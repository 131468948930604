import React, { useEffect} from 'react';
import {BsFacebook, BsInstagram, BsTiktok, BsFillTelephoneFill} from 'react-icons/bs';
import {ImLocation} from 'react-icons/im';
import {MdEmail} from 'react-icons/md';
import ScrollReveal from 'scrollreveal';
import './ContactStyles.css';
import ComForm from './ConForm';

export default function ContactForm(){

    useEffect(() => {
        const sr = ScrollReveal({
          origin: 'right',
          distance: '80px',
          duration: 2000,
          reset: false
        });
        sr.reveal('.contactform-title-h2', {delay: 200, origin:'left'});
        sr.reveal('.contactform-title', {delay: 220, origin:'left'});
        sr.reveal('.contactform-hr', {delay: 240, origin:'left'});
        sr.reveal('#contactform-location', {delay: 270, origin:'left'});
        sr.reveal('#contactform-phone', {delay: 330, origin:'left'});
        sr.reveal('#contactform-email', {delay: 390, origin:'left'});
        sr.reveal('#contactform-email', {delay: 390, origin:'left'});
        sr.reveal('.contact-social-media', {delay: 400, origin:'left'});
        sr.reveal('#name-form', {delay: 200, origin:'right'});
        sr.reveal('#phone-form', {delay: 240, origin:'right'});
        sr.reveal('#email-form', {delay: 370, origin:'right'});
        sr.reveal('#message-form', {delay: 330, origin:'right'});
        sr.reveal('#contactform-btn', {delay: 390, origin:'right'});
        return () => {
            sr.destroy();
          };
    }, []);


    return(
        <div className='contactform my-5'> 
            <div className="contact-form-contents py-5">
                <div className='container col-xxl-7'>
                    <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 justify-content-center align-items-center">
                        <div className="col">
                            <div>
                                <div>
                                    <h2 className='contactform-title-h2'>További információra van <br />szüksége?</h2>
                                    <h5 className='py-1 fw-bolder contactform-title'>Lépj velünk kapcsolatba</h5>
                                    <hr className="contactform-hr" />
                                    <div>
                                        <div className="d-grid py-3" id="contactform-location">
                                            <span className="fs-3 text-primary icon-link icon-link-hover" style={{ '--bs-icon-link-transform': 'translate3d(0, -.125rem, 0)' }} id="contacts-icons">
                                                <ImLocation className="bi"/>
                                            </span>        
                                            <span className='contactform-addres'>Székhely:</span>
                                            <span className='addres-title'>1111 Budapest Budafoki út 113.</span>
                                        </div>
                                        <div className="d-grid py-1" id="contactform-phone">
                                            <span className='fs-3 text-primary icon-link icon-link-hover' style={{ '--bs-icon-link-transform': 'translate3d(0, -.125rem, 0)' }} id="contacts-icons">
                                                <BsFillTelephoneFill className="bi" />
                                            </span>
                                            <span className='contactform-addres'>Telefonszám:</span>
                                            <a className='text-decoration-none addres-title' href="tel:+36205064321">+36 20 506 4321</a>
                                        </div>
                                        <div className="d-grid py-1" id="contactform-email">
                                            <span className="fs-3 text-primary icon-link icon-link-hover" style={{ '--bs-icon-link-transform': 'translate3d(0, -.125rem, 0)' }} id="contacts-icons">
                                                <MdEmail className="bi" />
                                            </span>
                                            <span className='contactform-addres'>Email cím:</span>
                                            <a className='text-decoration-none addres-title' href="mailto:info@hocktech.hu">info@westendautorental.hu</a>
                                        </div>
                                    </div>
                                </div>        
                                <div className="py-2 contact-social-media">
                                    <span className='contactform-addres'>Közösségi média:</span>
                                    <div classame="d-flex justify-content-around">
                                        <a 
                                            href="https://www.tiktok.com/@westendautorental" 
                                            className='mx-2 fs-3 icon-link icon-link-hover py-2' 
                                            style={{ '--bs-icon-link-transform': 'translate3d(0, -.125rem, 0)' }}
                                            target="_blank"
                                        >
                                            <BsTiktok className="bi" />
                                        </a>
                                        <a 
                                            href="https://www.facebook.com/profile.php?id=61556163058332" 
                                            className='mx-2 fs-3 icon-link icon-link-hover py-2' 
                                            style={{ '--bs-icon-link-transform': 'translate3d(0, -.125rem, 0)' }}
                                            target="_blank"
                                        >
                                            <BsFacebook className="bi" />
                                        </a>
                                        <a 
                                            href="https://www.instagram.com/westendautorental/" 
                                            className='mx-2 fs-3 icon-link icon-link-hover py-2' 
                                            style={{ '--bs-icon-link-transform': 'translate3d(0, -.125rem, 0)' }}
                                            target="_blank"
                                        >
                                            <BsInstagram className="bi" />
                                        </a>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <ComForm />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}