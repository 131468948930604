import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'; 

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY ,
  authDomain: "westendautorental-894c5.firebaseapp.com",
  projectId: "westendautorental-894c5",
  storageBucket: "westendautorental-894c5.appspot.com",
  messagingSenderId: "189548044224",
  appId: "1:189548044224:web:f4102a02b8723ba1d57225"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
export const storage = getStorage(app);