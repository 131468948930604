import React, { useContext, useState } from 'react';
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthContext } from './context/AuthContext';
import Home from './routes/Home'
import Contact from './routes/Contact';
import About from './routes/About';
import RentCar from './routes/RentCar';
import ScrollToTop from './components/scrolltotop/ScrollToTop';
import CategoryList from './routes/CategoryList';
import CarReview from './routes/CarReview';
import Login from './routes/Login';
import Dashboard from './routes/Dashboard';
import Loading from './components/loading/Loading';
import PrivacyPolicy from './routes/PrivacyPolicy';
import CarImport from './routes/CarImport';
import CarRent from './routes/CarRent.';
import CarTrade from './routes/CarTrade';
import CarAdministration from './routes/CarAdministration';
import Carinsurance from './routes/Carinsurance';
import CookieBar from './components/cookiebar/CookieBar';
import ErrorFound from './routes/ErrorFound';

function App() {
  const [loading, setLoading] = useState(true);
  const {currentUser} = useContext(AuthContext)
  const RequireAuth = ({children}) => {
    return currentUser ? (children) : <Navigate to="/login" />
  }
  console.log(currentUser);

  return (
    <Router>
      <ScrollToTop />
      <CookieBar />
      {loading ? (
        <Loading setLoading={setLoading} loading={loading} />
      ) : (
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='*' element={<ErrorFound />} />
          <Route path='/rentcar' element={<RentCar />} />
          <Route path="/rentcar/:collectionId" element={<CategoryList />} />
          <Route path="/rentcar/:collectionId/:productId" element={<CarReview />} />
          <Route path='/carimport' element={<CarImport />} />
          <Route path='/carrent' element={<CarRent />} />
          <Route path='/cartrade' element={<CarTrade />} />
          <Route path='/fullmanagement' element={<CarAdministration />} />
          <Route path='/carinsurance' element={<Carinsurance />} />
          <Route path='/aboutus' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/privacypolicy' element={<PrivacyPolicy />} />
          <Route path='/login' element={<Login/>} />
          <Route 
            path='/dashboard' 
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            } 
          />
        </Routes>
      )}
    </Router>
  );
} 

export default App;
