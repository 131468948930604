import React, {useEffect} from "react";
import ContactImage from '../../assets/contact/young-man-holding-car-keys-by-his-new-car.jpg';
import './ContactStyles.css';
import ScrollReveal from "scrollreveal";
import { Link } from "react-router-dom";

export default function Contact(){
    useEffect(() => {
        const sr = ScrollReveal({
          origin: 'right',
          distance: '80px',
          duration: 2000,
          reset: false
        });
        sr.reveal('#contact-image', {delay: 200, origin:'left'});
        sr.reveal('.contact-title', {delay: 250, origin:'right'});
        sr.reveal('.contact-description', {delay: 300, origin:'right'});
        sr.reveal('.contact-description1', {delay: 350, origin:'right'});
        sr.reveal('#rent-car-btn', {delay: 370, origin:'right'});
        sr.reveal('#rent-car-btn1', {delay: 400, origin:'right'});
        return() => {
            sr.destroy();
        }
    }, []);

    return(
        <div className="contact py-5">
            <div className="container col-xxl-8">
                <div className="row flex-lg-row-reverse align-items-center g-5">
                    <div className="col-lg-6 col-sm-12 col-md-12">
                        <h1 className="contact-title">Bérelj autót a legjobb helyről</h1>
                        <p className="lead contact-description">Szállj be az autók világába a mi segítségünkkel! Válaszd autóink széles választékából a tökéletes járművet, és indulj útnak kényelmesen és biztonságosan.</p>
                        <p className="lead contact-description1">Fedezd fel autóbérlésünk előnyeit, és tapasztald meg a szabadság érzését a volán mögött. Rugalmas árak, széles kínálat, és gyors foglalás - mi várunk, hogy elindítsd utazásodat!</p>    
                        <div classame="btn-group">
                            <Link to="/contact" className="mx-1 button-unique px-4 me-md-2 text-decoration-none" id="rent-car-btn">Kapcsolat</Link>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 order-first order-lg-last">
                        <img 
                            src={ContactImage}
                            className="d-block mx-lg-auto img-fluid rounded shadow" 
                            alt="Bootstrap Themes" 
                            width="700" 
                            id="contact-image"
                            height="500" 
                            loading="lazy"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}