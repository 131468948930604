import React, {useState, useEffect} from "react";
import './DashHomeStyles.css'
import { auth } from '../../firebase';

export default function DashHomeCard(){
    const [email, setEmail] = useState('');
    useEffect(() => {
        if (auth.currentUser) {
            setEmail(auth.currentUser.email);
        }
    }, []);
    
    return(
        <div className="container">
            <div className="card">
                <div className="card-body">
                    <h1 className="dashome-title text-center">Üdvözöllek a Főoldalon!</h1>
                    {email && (
                    <h5 className="dashhome-winlogin text-center text-color-success">
                        Sikeres bejelentkezés, {email}
                    </h5>
                    )}
                </div>
            </div>
        </div>
    )
}