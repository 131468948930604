import BreadCrumb from "../components/breadcrumb/BreadCrumb";
import Footer from "../components/footer/Footer";
import Navbar from "../components/navbar/Navbar";
import FullAdministration from "../components/services/fulladministration/FullAdministration";
import FullAdministrationCards from "../components/services/fulladministration/FullAdministrationCards";
import Consulation from "../components/services/fulladministration/Consulation";
import FullAdDescription from "../components/services/fulladministration/FullAdDescription";

export default function CarAdministration(){


    return(
        <>
            <Navbar />
            <BreadCrumb />
            <FullAdministration />
            <FullAdministrationCards />
            <Consulation />
            <FullAdDescription />
            <Footer />
        </>
    )
}