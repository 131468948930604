import React, {useEffect} from 'react';
import ScrollReveal from 'scrollreveal';
import CarImportImage from '../../../assets/services/transport-concept-with-parked-vehicles.jpg';

export default function CarImportDescription(){
    useEffect(() => {
        const sr = ScrollReveal({
          origin: 'right',
          distance: '80px',
          duration: 2000,
          reset: false
        });
        sr.reveal('#car-import-img', {delay: 300, origin:'right'});
        sr.reveal('#import-title', {delay: 350, origin:'left'});
        sr.reveal('#import-desc', {delay: 400, origin:'left'});
        return() => {
            sr.destroy();
        }
    }, []);


    return(
        <div className="car-import-desc py-5">
            <div className="container">                            
                <div className="row flex-lg-row-reverse align-items-center g-5">
                    <div className="col-12 col-md-12 col-sm-12 col-lg-6">
                        <img 
                            src={CarImportImage}
                            className="d-block mx-lg-auto img-fluid rounded" 
                            alt="Bootstrap Themes" 
                            width="700" 
                            height="500" 
                            loading="lazy"
                            id="car-import-img"
                        />
                    </div>
                    <div className="col-lg-6 col-sm-12 col-md-12 ">
                        <h1 className="rent-car-title" id="import-title">Autó import garanciával, külföldről</h1>
                        <p className="lead" style={{textAlign:'justify', marginBottom:'1.25rem'}} id="import-desc">Üdvözöljük az Autó import garanciával, külföldről szolgáltatásunk oldalán! Cégünk elkötelezett abban, hogy Önnek a legjobb minőségű és megbízható járműveket kínálja, amelyeket gondosan válogatott külföldi forrásokból importálunk. Az importált autóinkra 6 hónap kiterjedő garancia megadja az Önnek szükséges biztonságot és nyugalmat a vezetés során.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}