import React, {useEffect} from 'react';
import ScrollReveal from 'scrollreveal';
import './CarStyles.css';
import AdminsitrationImage from '../../../assets/services/close-up-busy-businesswoman.jpg';
import CheckingImage from '../../../assets/services/medium-shot-man-checking-car.jpg';
import WideImage from '../../../assets/services/row-new-cars-port.jpg';

export default function CarImportCards(){
    useEffect(() => {
        const sr = ScrollReveal({
          origin: 'right',
          distance: '80px',
          duration: 2000,
          reset: false
        });
        sr.reveal('.carimport-title', {delay: 250, origin:'left'});
        sr.reveal('.car-import-hr', {delay: 300, origin:'right'});
        sr.reveal('#importcards-1', {delay: 350, origin:'left'});
        sr.reveal('#importcards-2', {delay: 300, origin:'left'});
        sr.reveal('#importcards-3', {delay: 350, origin:'left'});
        return() => {
            sr.destroy();
        }
    }, []);

    return(
        <div className='car-import'>
            <div className="container">
                <h1 className="text-center carimport-title">Miért válassza külföld <br /> importált autóinkat?</h1>
                <hr className="car-import-hr mb-5" />
                <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-3 text-center py-5 g-5">
                    <div className="col" id="importcards-1">
                        <img src={WideImage} alt="" className='bd-placeholder-img rounded-circle shadow-sm' width="140" height="140" />
                        <h2 className="carimport-title">Széles Kínálat:</h2>
                        <p className='carimport-description'>Kínálatunkban megtalálhatók a legnépszerűbb és legkívánatosabb autómárkák és modellek, így könnyen megtalálhatja az Ön igényeinek megfelelő járművet.</p>
                    </div>
                    <div className="col" id="importcards-1">
                        <img src={AdminsitrationImage} alt="" className='bd-placeholder-img rounded-circle shadow-sm' width="140" height="140" />
                        <h2 className="carimport-title">Kiterjedő Garancia:</h2>
                        <p className='carimport-description'>Az importált autóinkra vonatkozó garancia biztosítja, hogy Ön nyugodtan élvezhesse új járművét, anélkül, hogy aggódna a váratlan problémák miatt.</p>
                    </div>
                    <div className="col" id="importcards-1">
                        <img src={CheckingImage} alt="" className='bd-placeholder-img rounded-circle shadow-sm' width="140" height="140" />
                        <h2 className="carimport-title">Teljes Körű Ellenőrzés:</h2>
                        <p className='carimport-description'>Minden import autónkat alaposan ellenőrizzük, hogy garantáljuk a magas minőséget és az optimális műszaki állapotot.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}