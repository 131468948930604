import React from 'react';
import { FaShop } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import './DashBoardStyles.css'
import DashHome from './DashHome';
import DashAnalytics from './DashAnalytics'
import DashCustomers from './DashCustomers';
import DashTutorial from './DashTutorial';

export default function DashSlide({openMenu, handleOpenMenu, handleSidebarClick}){
    return(
        <div className="sidebar col-md-3 col-lg-2 p-0 bg-dark sidebars" style={{height:'91.2vh'}}>
            <div 
                className={`offcanvas-md offcanvas-end bg-dark ${openMenu ? 'show' : ''}`} 
                tabIndex="-1" 
                id="sidebarMenu" 
                aria-labelledby="sidebarMenuLabel"
            >
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="sidebarMenuLabel">Company name</h5>
                    <button 
                        type="button" 
                        className="btn-close"
                        onClick={handleOpenMenu}
                    ></button>
                </div>
                <div className="offcanvas-body d-md-flex flex-column p-0 pt-lg-3">
                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <button className="nav-link d-flex align-items-center gap-2 active" onClick={() => handleSidebarClick(<DashHome />)} >
                                Főoldal
                            </button>
                        </li>
                        <li className="nav-item">
                            <button className="nav-link d-flex align-items-center gap-2" onClick={() => handleSidebarClick(<DashAnalytics />)} >
                                Analytika
                            </button>
                        </li>
                        <li className="nav-item">
                            <button className="nav-link d-flex align-items-center gap-2" onClick={() => handleSidebarClick(<DashCustomers />)}>
                                Termék feltöltés
                            </button>
                        </li>
                        <li className="nav-item">
                            <button className="nav-link d-flex align-items-center gap-2" onClick={() => handleSidebarClick(<DashTutorial />)}>
                                Tutorial videók
                            </button>
                        </li>
                    </ul>
                    <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-white text-uppercase">
                        <span>Üzlet <FaShop/></span>
                    </h6>
                    <ul className="nav flex-column mb-auto">
                        <li className="nav-item">
                            <Link className="nav-link d-flex align-items-center gap-2" to="/">
                                Üzlet megtekintése
                            </Link>
                        </li>
                    </ul>

                </div>
            </div>
        </div>
    );
}