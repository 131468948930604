import React, {useEffect} from "react";
import ScrollReveal from 'scrollreveal';
import HeroImage from '../../../assets/services/20943572.jpg';
import './InsuranceBonding.css'
import { Link } from "react-router-dom";

export default function Insurancebonding(){
    useEffect(() => {
        const sr = ScrollReveal({
          origin: 'right',
          distance: '80px',
          duration: 2000,
          reset: false // Ne állítsa vissza az animációkat a görgetés után
        });
        sr.reveal('#img-insurancebonding', {delay: 250, origin:'right'});
        sr.reveal('#title-iunsurancebonding', {delay: 250, origin:'left'});
        sr.reveal('#desc-iunsurancebonding', {delay: 300, origin:'left'});
        sr.reveal('#desc-iunsurancebonding1', {delay: 350, origin:'left'});
        sr.reveal('#btn-iunsurancebonding', {delay: 400, origin:'left'});
        return () => {
          sr.destroy();
        };
    }, [])

    return(
        <div className="insurancebondin py-5">
            <div className="container">
                <div className="row flex-lg-row-reverse align-items-center g-5">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                        <img 
                            src={HeroImage}
                            className="d-block mx-lg-auto img-fluid" 
                            alt="Bootstrap Themes" 
                            width="700" 
                            height="500" 
                            loading="lazy"
                            id="img-insurancebonding"
                        />
                    </div>
                    <div className="col-lg-6 col-sm-12 col-md-12">
                        <h1 className="display-5 text-body-emphasis lh-1 mb-3" id="title-iunsurancebonding"> Biztosítás Kötés</h1>
                        <p className="lead" style={{textAlign:'justify', marginBottom:'1.25rem'}} id="desc-iunsurancebonding">Üdvözöljük a WestendAutoRental biztosítási szolgáltatásának oldalán! Mivel fontosnak tartjuk ügyfeleink biztonságát és kényelmét, különféle biztosítási lehetőségeket kínálunk az Ön által vásárolt járművekre.</p>
                        <p className="lead" style={{textAlign:'justify', marginBottom:'1.25rem'}} id="desc-iunsurancebonding1">
                            Ügyfeleink védelme érdekében cégünk elkötelezett a gyors és hatékony ügyintézés mellett. Hiszünk abban, hogy az ügyfeleknek nemcsak a biztosítási lehetőségek széles skáláját kell biztosítanunk, hanem a teljes körű kiszolgálást is, beleértve minden ügyintézési folyamatot. Cégünk elintézi minden maga helyett, így Önnek nem kell feleslegesen aggódnia a bürokrácia vagy a papírmunka miatt.
                        </p>
                        <div className="button-aboutus-groupd-grid gap-2 d-md-flex justify-content-md-start" id="btn-iunsurancebonding">
                            <Link to="/contact" className="button-unique px-4 me-md-2" >Kapcsolat</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}