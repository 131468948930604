import React, {useEffect} from 'react';
import HeroImage from '../../../assets/services/fulladministrationimg.jpg';
import './FullAdministrationStyles.css';
import ScrollReveal from 'scrollreveal';

export default function Consulation(){
    useEffect(() => {
        const sr = ScrollReveal({
          origin: 'right',
          distance: '80px',
          duration: 2000,
          reset: false
        });
        sr.reveal('#img-fulladministration', {delay: 250, origin:'left'});
        sr.reveal('#consulation-title', {delay: 300, origin:'right'});
        sr.reveal('#consulation-desc', {delay: 350, origin:'right'});
        sr.reveal('#consulation-desc1', {delay: 400, origin:'right'});
        return() => {
            sr.destroy();
        }
    }, []);

    return(
        <div className="consulation py-5">
            <div className="container col-xxl-8">
                <div className="row flex-lg-row-reverse align-items-center g-5">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <h1 className="display-5 text-body-emphasis lh-1 mb-3" id="consulation-title">Hogyan zajlik a folyamat?</h1>
                        <p className="lead" style={{textAlign:'justify', marginBottom:'1.25rem'}} id="consulation-desc"><span>Személyes Konzultáció: </span>Találkozzunk, és megbeszéljük az autójával kapcsolatos igényeit és elvárásait.</p>
                        <p className="lead" style={{textAlign:'justify', marginBottom:'1.25rem'}} id="consulation-desc1"><span>Dokumentáció Ellenőrzése: </span>Az autóval kapcsolatos összes szükséges dokumentációt ellenőrizzük, és segítünk a hiányzó elemek pótlásában.</p>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 order-first order-lg-last">
                        <img 
                            src={HeroImage}
                            className="d-block mx-lg-auto img-fluid" 
                            alt="Bootstrap Themes" 
                            width="700" 
                            height="500" 
                            loading="lazy"
                            id="img-fulladministration"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}