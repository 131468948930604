import React, {useEffect} from 'react';
import HeroImage from '../../../assets/services/fulladministrationimg.jpg';
import './FullAdministrationStyles.css';
import ScrollReveal from 'scrollreveal';
import { Link } from 'react-router-dom';

export default function FullAdministration(){
    useEffect(() => {
        const sr = ScrollReveal({
          origin: 'right',
          distance: '80px',
          duration: 2000,
          reset: false
        });
        sr.reveal('#img-fulladministration1', {delay: 280, origin:'right'});
        sr.reveal('#fulladd-title1', {delay: 250, origin:'left'});
        sr.reveal('#fulladd-desc', {delay: 300, origin:'left'});
        sr.reveal('#btn-fulladd', {delay: 400, origin:'left'});
        return() => {
            sr.destroy();
        }
    }, []);

    return(
        <div className="fulladministration py-5">
            <div className="container col-xxl-8">
                <div className="row flex-lg-row-reverse align-items-center g-5">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                        <img 
                            src={HeroImage}
                            className="d-block mx-lg-auto img-fluid rounded" 
                            alt="Bootstrap Themes" 
                            width="700" 
                            height="500" 
                            loading="lazy"
                            id="img-fulladministration1"
                        />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <h1 className="display-5 text-body-emphasis lh-1 mb-3" id="fulladd-title1">Teljes Körű forgalomba helyezés</h1>
                        <p className="lead" style={{textAlign:'justify', marginBottom:'1.25rem'}} id="fulladd-desc">Ügyfeleink számára kínáljuk a teljes körű Magyarországon forgalomba helyezés és vizsgáztatás szolgáltatását. Célunk, hogy az autója minden szükséges engedéllyel és dokumentummal rendelkezzen, és minden szempontból megfeleljen az aktuális jogszabályoknak.</p>
                        <div className="button-aboutus-groupd-grid gap-2 d-md-flex justify-content-md-start" id="btn-fulladd">
                            <Link to="/contact" className="button-unique px-4 me-md-2" >Kapcsolat</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}