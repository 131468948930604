import React from "react";
import Hero from "../components/hero/Hero";
import RentCar from "../components/rentcar/RentCar";
import Footer from "../components/footer/Footer";
import Contact from "../components/contact/Contact";
import CompanyDescription from "../components/companydetail/CompanyDescription";
import RentCollection from "../components/rentcollection/RentCollection";
import Header from "../components/navbar/Header";
export default function Home(){
    return(
        <>
            <Header />
            <Hero />
            <RentCollection />
            <Contact />
            <CompanyDescription />
            <RentCar />
            <Footer />
        </>
    )
}