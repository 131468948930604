import React, {useEffect} from "react";
import ScrollReveal from 'scrollreveal'
import HeroImage from '../../../assets/services/20943572.jpg';

export default function InsuranceDescription(){
    useEffect(() => {
        const sr = ScrollReveal({
          origin: 'right',
          distance: '80px',
          duration: 2000,
          reset: false // Ne állítsa vissza az animációkat a görgetés után
        });
        sr.reveal('#img-insurancedescription', {delay: 300, origin:'left'});
        sr.reveal('#desc-insuranceDescription', {delay: 350, origin:'right'});
        sr.reveal('#desc-insuranceDescription1', {delay: 400, origin:'right'});
        return () => {
          sr.destroy();
        };
    }, [])

    return(
        <div className="insuranceDescription py-5">
            <div className="container">
                <div className="row flex-lg-row-reverse align-items-center g-5">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <p className="lead" style={{textAlign:'justify', marginBottom:'1.25rem'}} id="desc-insuranceDescription">Munkatársaink szakértelmének és hozzáértésének köszönhetően minden ügyintézést zökkenőmentesen elvégezünk az Ön nevében. Ennek eredményeként Önnek több ideje marad a fontos dolgokra, míg mi gondoskodunk a részletekről. Így a WestendAutoRental nem csupán járműbiztosítási szolgáltatásokat nyújt, hanem teljes körű támogatást is biztosít az ügyfelek számára.</p>
                        <p className="lead" style={{textAlign:'justify', marginBottom:'1.25rem'}} id="desc-insuranceDescription1">
                        Ezáltal garantáljuk, hogy minden ügyfélünk elégedetten távozik, hiszen cégünk nem csupán járművekhez nyújt védelmet, hanem az ügyfélkapcsolatokat is magas színvonalon kezeli. A WestendAutoRental, ahol minden ügyintézést elintézünk az Ön kényelme érdekében. Köszönjük, hogy velünk tart, és bizalmát a mi szolgáltatásainkba helyezi!
                        </p>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 order-first order-lg-last">
                        <img 
                            src={HeroImage}
                            className="d-block mx-lg-auto img-fluid" 
                            alt="Bootstrap Themes" 
                            width="700" 
                            height="500" 
                            loading="lazy"
                            id="img-insurancedescription"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}