import React, {useState} from "react";
import Logo from '../../assets/logo/logo.png'
import './NavbarStyles.css';
import {BsFacebook, BsInstagram, BsTiktok} from 'react-icons/bs';
import {AiOutlineClose } from 'react-icons/ai';
import {HiOutlineMenuAlt3} from 'react-icons/hi';
import { Link } from "react-router-dom";

export default function Header(){
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [color, setColor] = useState(false);

    const changeColor =() => {
        if(window.scrollY >= 100){
            setColor(true)
        } else {
            setColor(false)
        }
    }
    window.addEventListener('scroll', changeColor)

    const handleToggle = () => {
      setIsOpen(!isOpen);
    };

    const handleNavCollapse = () => {
      setIsNavCollapsed(!isNavCollapsed);
    };

    return(
        <nav className={color ? "navbar navbar-bg navbar-expand-lg fixed-bottom" : "navbar fixed-bottom navbar-expand-lg"}>
            <div className="container">
                <Link to="/" className="navbar-brand">
                    <img 
                        src={Logo} 
                        alt="" 
                        className="navbar-logo-img"
                    />
                </Link>
                <button
                    className="navbar-toggler border border-white text-white"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded={!isNavCollapsed ? true : false}
                    aria-label="Toggle navigation"
                    onClick={handleNavCollapse}
                    style={{ marginRight: "0.5rem"}}
                >
                    <span className="menu-icons">{isNavCollapsed ? <HiOutlineMenuAlt3 /> : <AiOutlineClose />}</span>
                </button>
                <div
                    className={`${
                    isNavCollapsed ? "collapse" : ""
                    } navbar-collapse justify-content-center`}
                    id="navbarNav"
                >
                    <ul className="navbar-nav">
                        <li className="nav-item navbar-link">
                            <Link to='/' className="nav-link fw-bolder" id="nav-link-hover">Főoldal</Link>
                        </li>
                        <li className="nav-item navbar-link">
                            <Link to='/rentcar' className="nav-link fw-bolder " id="nav-link-hover">Autóbérlés</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <a id="nav-link-hover"
                                className={`nav-link dropdown-toggle fw-bolder ${isOpen ? 'active' : ''}`}
                                role="button"
                                onClick={handleToggle}
                            >Szolgáltatások</a>
                            <ul className={`dropdown-menu my-lg-4 my-sm-0 my-md-0 ${isOpen ? 'show' : ''}`} style={{backgroundColor:'#212529'}}>
                                <li className="dropdown-li"><Link to="/carrent" className="dropdown-item text-white">Autóbérlés</Link></li>
                                <li className="dropdown-li"><Link to="/cartrade" className="dropdown-item text-white">Gépjármű kereskedelem</Link></li>
                                <li className="dropdown-li"><Link to="/carimport" className="dropdown-item text-white">Autó import</Link></li>
                                <li className="dropdown-li"><Link to="/fullmanagement" className="dropdown-item text-white" href="#">Teljes körű ügyintézés</Link></li>
                                <li className="dropdown-li"><Link to="/carinsurance" className="dropdown-item text-white" href="#">Biztosítás kötés</Link></li>
                            </ul>
                        </li>
                        <li className="nav-item navbar-link">
                            <Link to="/aboutus" className="nav-link fw-bolder" id="nav-link-hover">Rólunk</Link>
                        </li>
                        <li className="nav-item navbar-link">
                            <Link to='/contact' className="nav-link fw-bolder" id="nav-link-hover">Kapcsolat</Link>
                        </li>
                    </ul>
                </div>
                <div
                    className={`${
                        isNavCollapsed ? "d-none d-lg-block" : ""
                    } social-icons`}
                    >
                    <a target="_blank" href="www.facebook.com">
                        <BsFacebook className="fs-2 social-icon" />
                    </a>
                    <a target="_blank" href="https://www.instagram.com/westendautorental/">
                        <BsInstagram className="fs-2 social-icon" />
                    </a>
                    <a target="_blank" href="https://www.tiktok.com/@westendautorental">
                        <BsTiktok className="fs-2 social-icon" />
                    </a>
                </div>
            </div>
        </nav>
    )
}