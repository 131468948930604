import BreadCrumb from "../components/breadcrumb/BreadCrumb";
import Footer from "../components/footer/Footer";
import Navbar from "../components/navbar/Navbar";
import Insurancebonding from "../components/services/insurancebonding/Insurancebonding";
import InsuranceDescription from "../components/services/insurancebonding/InsuranceDescription";

export default function Carinsurance(){
    return(
        <>
            <Navbar />
            <BreadCrumb />
            <Insurancebonding />
            <InsuranceDescription />
            <Footer />
        </>
    )
}