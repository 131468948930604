import React, {useEffect} from 'react';
import ScrollReveal from "scrollreveal";
import BmwImage from '../../assets/hero/hero-bmw.png';
import './AboutStyles.css';

export default function AboutDescrpiton(){
    useEffect(() => {
        const sr = ScrollReveal({
          origin: 'right',
          distance: '80px',
          duration: 2000,
          reset: false // Ne állítsa vissza az animációkat a görgetés után
        });
        sr.reveal('#img-about', {delay: 250, origin:'left'});
        sr.reveal('#about-title', {delay: 300, origin:'right'});
        sr.reveal('#desc1', {delay: 350, origin:'right'});
        sr.reveal('#desc2', {delay: 400, origin:'right'});
        return () => {
          sr.destroy();
        };
    }, [])

    return(
        <div className="about-description py-5">
            <div className="container col-xxl-8">
                <div className="row flex-lg-row-reverse align-items-center g-1">
                    <div className="col-lg-6 col-sm-12 col-md-12">
                        <h3 className="about-tit text-body-emphasis lh-1 mb-3" id="about-title">Cégünk története:</h3>
                        <p className="lead" style={{textAlign:'justify', marginBottom:'1.25rem'}} id="desc1">Az AutoRental 2009-ben kezdte meg tevékenységét azzal a céllal, hogy minőségi és rugalmas autószolgáltatásokat nyújtson ügyfeleinek. Az évek során folyamatos fejlődésünknek és a kiváló szolgáltatásoknak köszönhetően ma már meghatározó szereplők vagyunk az autókölcsönzés, autó import és autó kereskedelem terén.</p>
                        <p className="lead" style={{textAlign:'justify', marginBottom:'1.25rem'}} id="desc2">Cégünk alapelvei között kiemelt szerepet kap a szakértelem és a megfelelő szintű szolgáltatásnyújtás. Képzett csapatunk elkötelezett abban, hogy ügyfeleink elégedettségét maximálisan kiszolgáljuk. Tapasztalatunk és elkötelezettségünk révén bízunk benne, hogy Ön is megtalálja nálunk az ideális autót vagy szolgáltatást. A Budapest - Szeged vonzáskörzetében történő tevékenységünk révén kívánunk még hatékonyabban és gyorsabban szolgálni ügyfeleink igényeit.</p>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 order-first order-lg-last">
                        <img 
                            src={BmwImage}
                            className="d-block mx-lg-auto img-fluid" 
                            alt="Bootstrap Themes" 
                            width="700" 
                            id="img-about"
                            height="500" 
                            loading="lazy"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}