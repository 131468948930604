import React, { useState, useEffect } from 'react';
import {BsFillPeopleFill, BsFillCalendarCheckFill, BsFillCarFrontFill} from 'react-icons/bs';
import {GiCheckMark} from 'react-icons/gi'
import ScrollReveal from 'scrollreveal';
import './CompanyStyles.css';

export default function CompanyDescription() {
    const [count1, setCount1] = useState(0);
    const [count2, setCount2] = useState(0);
    const [count3, setCount3] = useState(0);
    const [count4, setCount4] = useState(0);
    const [stopCounting1, setStopCounting1] = useState(false);
    const [stopCounting2, setStopCounting2] = useState(false);
    const [stopCounting3, setStopCounting3] = useState(false);
    const [stopCounting4, setStopCounting4] = useState(false);
  
    useEffect(() => {
      if (count1 < 41 && !stopCounting1) {
        const timer = setTimeout(() => {
          setCount1(count1 + 1);
        }, 100); // 1 másodperc intervallum az első számhoz
        return () => clearTimeout(timer);
      }
    }, [count1, stopCounting1]);
  
    useEffect(() => {
      if (count2 < 15 && !stopCounting2) {
        const timer = setTimeout(() => {
          setCount2(count2 + 1);
        }, 100); // 1,5 másodperc intervallum a második számhoz
        return () => clearTimeout(timer);
      }
    }, [count2, stopCounting2]);
  
    useEffect(() => {
      if (count3 < 1200 && !stopCounting3) {
        const timer = setTimeout(() => {
          setCount3(count3 + 1);
        }, 100); // 2 másodperc intervallum a harmadik számhoz
        return () => clearTimeout(timer);
      }
    }, [count3, stopCounting3]);
  
    useEffect(() => {
      if (count4 < 1500 && !stopCounting4) {
        const timer = setTimeout(() => {
          setCount4(count4 + 1);
        }, 100); // 2,5 másodperc intervallum a negyedik számhoz
        return () => clearTimeout(timer);
      }
    }, [count4, stopCounting4]);

    useEffect(() => {
      const sr = ScrollReveal({
        origin: 'top',
        distance: '80px',
        duration: 2000,
        reset: false // Ne állítsa vissza az animációkat a görgetés után
      });
      sr.reveal('#order-1', { delay: 400, origin: 'left' });
      sr.reveal('#order-2', { delay: 350, origin: 'left' });
      sr.reveal('#order-3', { delay: 350, origin: 'right' });
      sr.reveal('#order-4', { delay: 400, origin: 'right' });
      return () => {
        sr.destroy();
      };
    }, [])
  
    return (
        <div className='company-description py-5'>
            <div className='container py-5'>
                <div className="row row-cols-md-2 row-cols-sm-2 row-cols-2 row-cols-lg-4 py-5 align-items-center g-4">
                    <div className='col text-center' id="order-1">
                        <span className="fs-2 company-border-span">
                          <BsFillCarFrontFill className="fs-1"/>
                        </span>
                        <h5 className='mt-4 comapny-title'>Bérelhető autók</h5>
                        <h5 className="comapny-description">{count1}</h5>
                    </div>
                    <div className='col text-center' id="order-2">
                        <div className="company-border">
                            <span className="fs-1 company-border-span">
                              <BsFillCalendarCheckFill className="fs-1"/>
                            </span>
                            <h5 className="mt-4 comapny-title">Évek</h5>
                            <h5 className="comapny-description">{count2}</h5>
                        </div>
                    </div>
                    <div className='col text-center' id="order-3">
                        <div className="company-border">
                            <span className="fs-2 company-border-span">
                              <BsFillPeopleFill className="fs-1"/>
                            </span>
                            <h5 className="mt-4 comapny-title">Ügyfelek</h5>
                            <h5 className="comapny-description">{count3}</h5>
                        </div>
                    </div>
                    <div className='col text-center' id="order-4">
                        <div className="company-border">
                            <span className="fs-1 company-border-span">
                              <GiCheckMark className="company-icon" />
                            </span>
                            <h5 className="mt-4 comapny-title">Elégedett ügyfél</h5>
                            <h5 className="comapny-description">{count4}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}