import React, {useEffect} from 'react';
import ScrollReveal from 'scrollreveal';
import CheckImage from '../../../assets/services/medium-shot-man-checking-car.jpg';
import ExpertImage from '../../../assets/services/mechanics-repairing-car-workshop.jpg';
import WidgeRangeImage from '../../../assets/services/close-up-busy-businesswoman.jpg';
import './FullAdministrationStyles.css'

export default function FullAdministrationCards(){
    useEffect(() => {
        const sr = ScrollReveal({
          origin: 'right',
          distance: '80px',
          duration: 2000,
          reset: false
        });
        sr.reveal('#fulladdcard-title', {delay: 230, origin:'right'});
        sr.reveal('#hr-caradd', {delay: 260, origin:'left'});
        sr.reveal('#card-fulladd-1', {delay: 310, origin:'right'});
        sr.reveal('#card-fulladd-2', {delay: 360 , origin:'right'});
        sr.reveal('#card-fulladd-3', {delay: 400, origin:'right'});
        return() => {
            sr.destroy();
        }
    }, []);

    return(
        <div className='full-administration-cards'>
            <div className="container">
                <h1 className="text-center carimport-title" id="fulladdcard-title">Miért válassza<br /> szolgáltatásainkat?</h1>
                <hr className="car-import-hr mb-5" id="hr-caradd" />
                <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-3 text-center py-5 g-5">
                    <div className="col" id="card-fulladd-1">
                        <img src={ExpertImage} alt="" className='bd-placeholder-img rounded-circle' width="140" height="140" />
                        <h2 className="carimport-title">Szakértői Tudás:</h2>
                        <p className='carimport-description'>Tapasztalt és szakértői csapatunk gondoskodik arról, hogy minden szükséges lépést megtegyünk az autója jogszabályoknak való megfeleléséért.</p>
                    </div>
                    <div className="col" id="card-fulladd-2">
                        <img src={CheckImage} alt="" className='bd-placeholder-img rounded-circle' width="140" height="140" />
                        <h2 className="carimport-title">Gyors és Hatékony Folyamat:</h2>
                        <p className='carimport-description'>Az adminisztrációs terhek csökkentése érdekében gyors és hatékony szolgáltatást nyújtunk, hogy Ön ne veszítse el feleslegesen az idejét.</p>
                    </div>
                    <div className="col" id="card-fulladd-3">
                        <img src={WidgeRangeImage} alt="" className='bd-placeholder-img rounded-circle' width="140" height="140" />
                        <h2 className="carimport-title">Rugalmas Megoldások:</h2>
                        <p className='carimport-description'>Az egyedi igényekre szabott megoldásokkal segítünk, hogy a szolgáltatásaink maximálisan illeszkedjenek az Ön autója és helyzete specifikus követelményeihez.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}