import React, {useEffect} from 'react';
import HeroImage from '../../../assets/services/651.jpg';
import './CarTradeStyles.css'
import ScrollReveal from 'scrollreveal';

export default function CarBuy(){
    useEffect(() => {
        const sr = ScrollReveal({
          origin: 'right',
          distance: '80px',
          duration: 2000,
          reset: false
        });
        sr.reveal('#img-carbuy', {delay: 250, origin:'right'});
        sr.reveal('#carbuy-title', {delay: 300, origin:'left'});
        sr.reveal('#carbuy-desc', {delay: 350, origin:'left'});
        return() => {
            sr.destroy();
        }
    }, []);

    return(
        <div className="consulation py-5">
            <div className="container col-xxl-8">
                <div className="row flex-lg-row-reverse align-items-center g-5">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                        <img 
                            src={HeroImage}
                            className="d-block mx-lg-auto img-fluid" 
                            alt="" 
                            width="700" 
                            height="500" 
                            loading="lazy"
                            id="img-carbuy"
                        />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <h1 className="display-5 text-body-emphasis lh-1 mb-3" id="carbuy-title">Vásárlás</h1>
                        <p className="lead" style={{textAlign:'justify', marginBottom:'1.25rem'}} id="carbuy-desc">Önnek lehetősége van választani készletünkben lévő autóink közül, vagy egyedi igényei alapján rendelni. Mi gondoskodunk arról, hogy az Ön által kiválasztott autót - márkától és típustól függetlenül - a legmegfelelőbb felszereltséggel szerezzük be Önnek.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}