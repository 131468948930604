import React, {useState, useEffect} from 'react';
import ScrollReveal from 'scrollreveal';
import './AccordionStyles.css';
import { accordionData } from '../../data';

export default function AbAccordion(){
    const [activeAccordion, setActiveAccordion] = useState(null);
    const isAccordionActive = (index) => activeAccordion === index;
    const toggleAccordion = (index) => {
        if (activeAccordion === index) {
            setActiveAccordion(null);
        } else {
            setActiveAccordion(index);
        }
    };

    useEffect(() => {
        const sr = ScrollReveal({
            origin: 'right',
            distance: '80px',
            duration: 2000,
            reset: false // Ne állítsa vissza az animációkat a görgetés után
        });
        sr.reveal('.accordion-card', {delay: 300, origin:'right'});
        return () => {
            sr.destroy();
        };
    }, []);

    return (
        <div className="anaccordion py-5">
            <div className="container">
                <div className="accordion accordion-card " id="accordionExample">
                    {accordionData.map((item, index) => (
                        <div className="accordion-item" key={index}>
                            <h2 className="accordion-header">
                                <button
                                className={`accordion-button ${isAccordionActive(index) ? '' : 'collapsed'}`}
                                type="button"
                                onClick={() => toggleAccordion(index)}
                                >
                                    {item.title}
                                </button>
                            </h2>
                            <div
                                className={`accordion-collapse collapse ${isAccordionActive(index) ? 'show' : ''}`}
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                {item.content} {item.description}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}