import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'; 
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import ScrollReveal from 'scrollreveal';
import './RentStyles.css';

export default function RentCars({ setProducts, products, collectionId}) {
    useEffect(() => {
        const fetchData = async () => {
          try {
            if (!collectionId) {
              console.error('Empty collectionId');
              return;
            }    
            const querySnapshot = await getDocs(collection(db, collectionId));
            const data = querySnapshot.docs.map((doc) => ({
                id: doc.id,
              ...doc.data(),
            }));
            setProducts(data);
              console.log(`Data fetched from Firebase for collection ${collectionId} successfully`);
            } catch (error) {
              console.error('Error fetching products:', error.message);
            }
          };
        fetchData();
    }, [collectionId]);

    useEffect(() => {
        const sr = ScrollReveal({
          origin: 'top',
          distance: '80px',
          duration: 2000,
          reset: false
        });
        sr.reveal('.rentcar-title-category', {delay: 200});
        sr.reveal('.rentcard-cards', {delay: 250});
        return() => {
            sr.destroy();
        }
    }, []);

    return (
        <div className="categorylist py-sm-3 py-md-5 py-lg-5 py-3">
            <div className="container">
                <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 g-2 my-5">
                    {products.map((product) => (
                    <div className="col" key={product.id} id="card-category">
                        <Link to={`/rentcar/${collectionId}/${product.id}`} className="text-decoration-none">
                            <div
                                className="card card-category" 
                                style={{
                                    backgroundColor:'#ffffff',
                                    textDecoration:'none',
                                    border:'none'
                                }}
                            >
                                <img
                                    src={product.imageUrls}
                                    className="card-img-top cardimg-rent"
                                    alt={product.name}
                                />
                                <div className="card-body text-center">
                                    <h3 className='rentcar-title text-center'>{product.caraddres}</h3>
                                    <p className='text-center'>{product.dayrent} Ft <span className='fw-bold'>/ nap</span></p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    ))}
                </div>
            </div>
        </div>
    );
}