import React, {useEffect} from 'react';
import HeroImage from '../../../assets/services/Vehicle Sale-cuate.svg';
import './CarTradeStyles.css'
import ScrollReveal from 'scrollreveal';

export default function CarSale(){
    useEffect(() => {
        const sr = ScrollReveal({
          origin: 'right',
          distance: '80px',
          duration: 2000,
          reset: false
        });
        sr.reveal('#img-fulladministration', {delay: 250, origin:'left'});
        sr.reveal('#consulation-title', {delay: 300, origin:'right'});
        sr.reveal('#consulation-desc', {delay: 350, origin:'right'});
        sr.reveal('#consulation-desc1', {delay: 400, origin:'right'});
        return() => {
            sr.destroy();
        }
    }, []);

    return(
        <div className="consulation py-5">
            <div className="container col-xxl-8">
                <div className="row flex-lg-row-reverse align-items-center g-5">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <h1 className="display-5 text-body-emphasis lh-1 mb-3" id="consulation-title">Eladás</h1>
                        <p className="lead" style={{textAlign:'justify', marginBottom:'1.25rem'}} id="consulation-desc">Ha Ön autója eladását tervezi, mi segítünk! Akár közvetlen vásárlással, akár bizományos értékesítéssel is, garantáljuk, hogy megtaláljuk az ideális megoldást. Felhasználjuk meglévő vevőkörünket, és az internetes platformokat is kihasználva gyors és hatékony eladást biztosítunk Önnek, minimális jutalék mellett.</p>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 order-first order-lg-last">
                        <img 
                            src={HeroImage}
                            className="d-block mx-lg-auto img-fluid" 
                            alt="" 
                            width="700" 
                            height="500" 
                            loading="lazy"
                            id="img-fulladministration"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}