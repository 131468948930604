import BreadCrumb from "../components/breadcrumb/BreadCrumb"
import Footer from "../components/footer/Footer"
import Navbar from "../components/navbar/Navbar"
import RentAllCars from "../components/services/rentcar/RentAllCars"
import RentUniqueOffer from "../components/services/rentcar/RentUniqueOffer"
import RentalCars from "../components/services/rentcar/RentalCars"

export default function CarRent(){


    return(
        <>
            <Navbar/>
            <BreadCrumb/>
            <RentalCars />
            <RentAllCars />
            <RentUniqueOffer/>
            <Footer/>
        </>
    )
}