import React, { useEffect } from "react";
import './LoadingStyles.css'

export default function Loading({ setLoading }) {

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 3000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="loading">
            <div className="container">
                <div className="wrapper">
                    <svg className="wrapper-svg">
                        <text x="50%" y="50%" textAnchor="middle" className="wrapper-text">
                            Westend
                        </text>
                    </svg>
                    <svg className="wrapper-svg">
                        <text x="50%" y="50%" textAnchor="middle" className="wrapper-text">
                            Auto
                        </text>
                    </svg>
                    <svg className="wrapper-svg">
                        <text x="50%" y="50%" textAnchor="middle" className="wrapper-text">
                            Rental
                        </text>
                    </svg>
                </div>
            </div>
        </div>
    )
}
