
import React, {useState, useEffect} from "react";
import {AiOutlineClose} from 'react-icons/ai';
import { db, storage } from '../../firebase';
import { addDoc, collection, serverTimestamp, updateDoc, doc, getDoc, onSnapshot } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

export default function DashEdited({ togglePopup, selectedVehicleData }) {
    const [uploadedImageUrls, setUploadedImageUrls] = useState([]);
    const [week, setWeek] = useState('');
    const [caraddres, setCarAddres] = useState('');
    const [name, setName] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [vintage, setVintage] = useState('');
    const [fuel, setFuel] = useState('');
    const [cosumption, setCosumption] = useState('');
    const [aircod, setAircod] = useState('');
    const [gearbox, setGearbox] = useState('');
    const [control, setControl] = useState('');
    const [dbpeople, setDbpeople] = useState('');
    const [description, setDescription] = useState('');
    const [dayrent, setDayRent] = useState('');
    const [monthrent, setMonthRent] = useState('');
    const [selectedCollection, setSelectedCollection] = useState('archive');
    const [isUploadSuccess, setIsUploadSuccess] = useState(false);
    const [imageUpload, setImageUpload] = useState([]);
  
    useEffect(() => {
        if (selectedVehicleData) {
            setName(selectedVehicleData.name || '' );
            setCountry(selectedVehicleData.country || '' );
            setState(selectedVehicleData.state || '' );
            setDescription(selectedVehicleData.description || '');
            setVintage(selectedVehicleData.vintage || '' );
            setFuel(selectedVehicleData.fuel || '' );
            setCosumption(selectedVehicleData.cosumption || '' );
            setAircod(selectedVehicleData.aircod || '' );
            setGearbox(selectedVehicleData.gearbox || '' );
            setControl(selectedVehicleData.control || '' );
            setDbpeople(selectedVehicleData.dbpeople || '' );
            setDayRent(selectedVehicleData.dayrent || '' );
            setMonthRent(selectedVehicleData.monthrent || '' );
            setWeek(selectedVehicleData.week || '' );
            setCarAddres(selectedVehicleData.caraddres || '' );
            setSelectedCollection('archive');
            setUploadedImageUrls(selectedVehicleData.imageUrls || []);
        }
    }, [selectedVehicleData]);
  
    const handleImageChange = (e) => {
        const files = e.target.files;
        const uploadedImages = [];
        for (let i = 0; i < files.length; i++) {
            uploadedImages.push(files[i]);
        }
        setImageUpload(uploadedImages);
    };
  
    const handleModify = async (e) => {
        e.preventDefault();
        try {
            if (!selectedVehicleData) {
              console.error('Selected vehicle data is undefined or null.');
              return;
            }
            const uploadTasks = imageUpload.map((file) => {
                const storageRef = ref(storage, `images/${file.name}`);
                const uploadTask = uploadBytesResumable(storageRef, file);
                return new Promise((resolve, reject) => {
                    uploadTask.on(
                        'state_changed',
                        (snapshot) => {},
                        (error) => {
                            console.error('Error uploading image:', error);
                            reject(error);
                        },  
                        async () => {
                            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                            resolve(downloadURL);
                        }
                    );
                });
            });
            const downloadURLs = await Promise.all(uploadTasks);
            const productRef = doc(db, selectedCollection, selectedVehicleData.id);
            await updateDoc(productRef, {
                name: name,
                caraddres: caraddres,
                state: state,
                country: country,
                vintage: vintage,
                fuel: fuel,
                cosumption: cosumption,
                aircod: aircod,
                gearbox: gearbox,
                control: control,
                dbpeople: dbpeople,
                description: description,
                dayrent: dayrent,
                week: week,
                monthrent: monthrent,
                imageUrls: [...uploadedImageUrls, ...downloadURLs],
                timeStamp: serverTimestamp(),
            });
            setIsUploadSuccess(true);
        } catch (err) {
            console.error('Error:', err);
        }
    };
  
    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleStateChange = (e) => {
        setState(e.target.value);
    };

    const handleCountryChange = (e) => {
        setCountry(e.target.value);
    };

    const handleVintageChange = (e) => {
        setVintage(e.target.value);
    };
    
    const handleFuelChange = (e) => {
        setFuel(e.target.value);
    };

    const handleCosumptionChange = (e) => {
        setCosumption(e.target.value);
    };

    const handleAirCodChange = (e) => {
        setAircod(e.target.value);
    };

    const handleGearboxChange = (e) => {
        setGearbox(e.target.value);
    };

    const handleControlChange = (e) => {
        setControl(e.target.value);
    };
    
    const handleDbPeopleChange = (e) => {
        setDbpeople(e.target.value);
    };

    const handleDayRentChange = (e) => {
        setDayRent(e.target.value);
    };

    const handleMonthRentChange = (e) => {
        setMonthRent(e.target.value);
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };
  
    const handleCollectionChange = (e) => {
        setSelectedCollection(e.target.value);
    };

    const handleDeleteImage = (index) => {
        const updatedImages = [...uploadedImageUrls];
        updatedImages.splice(index, 1);
        setUploadedImageUrls(updatedImages);
    };
  
    const handleDeleteImages = () => {
        setUploadedImageUrls([]);
    };

    const handleWeekChange = (e) => {
        setWeek(e.target.value);
    };
  
    const handleCarAddresChange = (e) => {
        setCarAddres(e.target.value);
    };

  
    return (
        <div className='modal-background-1 py-5 overflow-y-auto'>
            <div className='container'>
                <div className="scrollable-content overflow-auto mt-5 card-over1">
                    <button onClick={togglePopup} className='btn btn-primary my-2' style={{ float: 'right' }}>
                      <AiOutlineClose />
                    </button>
                        <div className='card card-dashadd'>
                            {isUploadSuccess && (
                                <div className='alert alert-success mt-2 text-center w-100 '>
                                    <p className='fw-bolder'>Sikeresen módosítottad az adatokat!</p>
                                </div>
                            )}
                            <form onSubmit={handleModify} className='card-body'>
                                <div className='row g-2'>
                                {uploadedImageUrls.length > 0 && (
                                    <div>
                                        <p>Már feltöltött képek:</p>
                                        <div className="d-flex flex-row mb-3">
                                            {uploadedImageUrls.map((url, index) => (
                                            <div key={index} className="card" style={{borderRadius:'0px 0px 10px 10px'}}>
                                                <img
                                                    src={url}
                                                    className="card-img-top"
                                                    alt={`Uploaded Image ${index}`}
                                                    style={{ maxWidth: '100%', maxHeight: '100px', marginRight: '10px', borderRadius:'0px 0px 0px 0px' }}
                                                />
                                                <div>
                                                    <button
                                                      type='button'
                                                      className='btn btn-danger w-100'
                                                      style={{borderRadius:'0px 0px 10px 10px'}}
                                                      onClick={() => handleDeleteImage(index)}
                                                    >
                                                      Törlés
                                                    </button>
                                                </div>
                                            </div>
                                            ))}
                                        </div>
                                        <button type='button' className='btn btn-danger mt-2' onClick={handleDeleteImages}>
                                            Képek Törlése
                                        </button>
                                    </div>
                                )}
                                <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                    <input type='file' className='form-control' onChange={handleImageChange} multiple />
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                    <input className='form-control' type='text' placeholder='Cím' value={caraddres} onChange={handleCarAddresChange} />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <input className='form-control' type="text" placeholder='napi bérlési ár' value={dayrent} onChange={handleDayRentChange} />
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                    <input className='form-control' type='text' placeholder='Heti bérlési ár' value={week} onChange={handleWeekChange} />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <input className='form-control' type="text" placeholder='Havi bérlési ár' value={monthrent} onChange={handleMonthRentChange} />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <input className='form-control' type="text" placeholder='kaukció' value={country} onChange={handleCountryChange} />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <input className='form-control' type="text" placeholder='Márka' value={name} onChange={handleNameChange} />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <input className='form-control' type="text" placeholder="Modell" value={state} onChange={handleStateChange} />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <input className='form-control' type="text" placeholder='Évjárat' value={vintage} onChange={handleVintageChange} />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <input className='form-control' type="text" placeholder='Üzemanyag' value={fuel} onChange={handleFuelChange} />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <input className='form-control' type="text" placeholder='Fogyasztás' value={cosumption} onChange={handleCosumptionChange} />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <input className='form-control' type="text" placeholder='Klíma' value={aircod} onChange={handleAirCodChange} />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <input className='form-control' type="text" placeholder='Váltó' value={gearbox} onChange={handleGearboxChange} />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <input className='form-control' type="text" placeholder='Tempomat' value={control} onChange={handleControlChange} />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <input className='form-control' type="text" placeholder='Szállítható személyek száma' value={dbpeople} onChange={handleDbPeopleChange} />
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                    <select className='form-control' value={selectedCollection} onChange={handleCollectionChange}>
                                        <option value='archive'>Válassz Kollekciót</option>
                                        <option value='olcso'>Olcsó</option>
                                        <option value='kozep'>Közep</option>
                                        <option value='felso'>Felső</option>
                                        <option value='minibus'>Kisbusz</option>
                                        <option value='truks'>Teherautó</option>
                                    </select>
                                </div>
                                <div className='col-12'>
                                    <textarea
                                        className='form-control w-100'
                                        style={{
                                            maxHeight: '300px',
                                            minHeight: '200px',
                                        }}
                                        value={description}
                                        onChange={handleDescriptionChange}
                                    ></textarea>
                                </div>
                            </div>
                            <button type='submit' className='btn btn-primary mt-2'>Módosítás</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}