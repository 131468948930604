import React, {useState, useEffect} from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import ScrollReveal from 'scrollreveal';
import BmwImage from '../../assets/collectionList/RentCarCollectionImage.jpg';
import RenaultImage from '../../assets/collectionList/RentCarCollectionListImage.jpg';
import MercedesImage from '../../assets/collectionList/RentCollectionListImage.jpg';
import MinibusImage from '../../assets/collectionList/minibus.png';
import TruckCarImage from '../../assets/collectionList/platos.png';

import './RentCollectionStyles.css';

export default function RentCollection() {
    const [collections, setCollections] = useState([]);

    useEffect(() => {
        const fetchCollections = async () => {
            const db = getFirestore();
            const collectionsRef = collection(db, 'yourCollectionName'); 

            try {
                const snapshot = await getDocs(collectionsRef);
                const collectionsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setCollections(collectionsData);
            } catch (error) {
                console.error('Error fetching collections: ', error);
            }
        };
        fetchCollections();

        const sr = ScrollReveal({
            origin: 'right',
            distance: '80px',
            duration: 2000,
            reset: false
        });
        sr.reveal('.rentcoll-title', { delay: 200, origin:'left' });
        sr.reveal('#rent-car-cheap', { delay: 300, origin:'right' });
        sr.reveal('#rent-car-medium', { delay: 350, origin:'right' });
        sr.reveal('#rent-car-luxury', { delay: 300, origin:'right'});
        return() => {
            sr.destroy();
        }
    }, []);


    return (
        <div className="rentcollection py-5">
            <div className="container py-5 my-5">
                <h3 className='rentcoll-title'>Kollekciók</h3>
                <div className="row row-cols-sm-1 row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 justify-content-center">
                    <div className="col rent-card">
                        <Link className='text-center text-decoration-none' to="/rentcar/olcso">
                            <div className="card shadow" id="rent-car-cheap">
                                <img src={RenaultImage } className="card-img-top cars-image" alt="" />
                                <div className="card-body">
                                    <h5 className="rentcollection-title">Olcsó autók</h5>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col rent-card">
                        <Link className='text-center text-decoration-none' to="/rentcar/kozep">
                            <div className="card shadow-lg" id="rent-car-medium">
                                <img src={BmwImage} className="card-img-top cars-image" alt="" />
                                <div className="card-body">
                                    <h5 className="rentcollection-title">Középkategóriás autók</h5>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col rent-card">
                        <Link className='text-center text-decoration-none' to="/rentcar/felso">
                            <div className="card shadow" id="rent-car-luxury">
                                <img src={MercedesImage} className="card-img-top cars-image" alt="" />
                                <div className="card-body">
                                   <h5 className='rentcollection-title'>Felsőkategóriás autók</h5>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col rent-card">
                        <Link className='text-center text-decoration-none' to="/rentcar/minibus">
                            <div className="card shadow" id="rent-car-luxury">
                                <img src={MinibusImage} className="card-img-top cars-image" alt="" />
                                <div className="card-body">
                                   <h5 className='rentcollection-title'>Kisbuszok</h5>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col rent-card">
                        <Link className='text-center text-decoration-none' to="/rentcar/truks">
                            <div className="card shadow" id="rent-car-luxury">
                                <img src={TruckCarImage} className="card-img-top cars-image" alt="" />
                                <div className="card-body">
                                   <h5 className='rentcollection-title'>Teherautók</h5>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}        