import React from 'react';
import './BookingStyles.css';
import {AiOutlineClose} from 'react-icons/ai';
import { useForm, ValidationError } from '@formspree/react';

export default function Book({car, toggleMenu}) {
    const [state, handleSubmit] = useForm("xyyrkpvz");
    const carName = car.name; 

    if (state.succeeded) {
        return <p>Sikeresen elküldtük az űrlapot!</p>;
    }

    return (
        <div className="modal-background">
            <div className="container">
                <div className='card-over'>
                    <div className="card card-booking" >
                        <form onSubmit={handleSubmit} className="card-body">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="col">
                                    <div className="form-floating mb-3" style={{fontFamily:'Poppins', fontSize:'20px'}}>
                                        {carName}
                                        <input
                                            type="hidden"
                                            name="carName" // Új mező a Formspree-nek
                                            value={carName} // Az autó neve
                                        />
                                    </div>
                                </div>
                                <div className="col text-end">
                                    <button className="btn btn-primary" onClick={toggleMenu}><AiOutlineClose /></button>
                                </div>
                            </div>
                            <div className="d-grid g-3">
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInput"
                                        name="day"
                                    />
                                    <label htmlFor="floatingInput">Hány napra szeretné bérelni az autót?</label>
                                    <ValidationError
                                        prefix="day"
                                        field="day"
                                        errors={state.errors}
                                    />
                                </div>
                                <div className="row g-2">
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-12">
                                        <div className="form-floating mb-3">
                                            <input
                                                type="date"
                                                name="datein"
                                                className="form-control"
                                                id="floatingInput"
                                                required
                                            />
                                            <label htmlFor="floatingInput">Kezdő dátum</label>
                                            <ValidationError
                                                prefix="datein"
                                                field="datein"
                                                errors={state.errors}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-12">
                                        <div className="form-floating mb-3">
                                            <input
                                                type="date"
                                                name="dateout"
                                                className="form-control"
                                                id="floatingInput"
                                                required
                                            />
                                            <label htmlFor="floatingInput">Befejező dátum</label>
                                            <ValidationError
                                                prefix="dateout"
                                                field="dateout"
                                                errors={state.errors}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row g-2">
                                    <div className="col">
                                        <div className="form-floating mb-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="floatingInput"
                                                name="phone"
                                                required
                                            />
                                            <label htmlFor="floatingInput">Telefonszám</label>
                                            <ValidationError
                                                prefix="phone"
                                                field="phone"
                                                errors={state.errors}
                                            />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-floating mb-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="floatingInput"
                                                name="name"
                                                required
                                            />
                                            <label htmlFor="floatingInput">Név</label>
                                            <ValidationError
                                                prefix="name"
                                                field="name"
                                                errors={state.errors}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInput"
                                        name="email"
                                        required
                                    />
                                    <label htmlFor="floatingInput">Email címe</label>
                                    <ValidationError
                                        prefix="email"
                                        field="email"
                                        errors={state.errors}
                                    />
                                </div>
                                <div className="form-floating">
                                    <textarea
                                        className="form-control"
                                        id="floatingTextarea2"
                                        name="message"
                                        placeholder="Kérdés / kérés"
                                        style={{maxHeight: '230px', minHeight:'180px'}}
                                    ></textarea>
                                    <ValidationError
                                        prefix="message"
                                        field="message"
                                        errors={state.errors}
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-start mx-1">
                                <div>
                                    <input 
                                        type="checkbox"
                                        id="acceptTerms"
                                        name="acceptTerms"
                                        required
                                    /> <span htmlFor="floatingInput">Elfogadom az adatkezelési tájékoztatót.</span>
                                </div>
                                <div className="form-floating">
                                    <button
                                        type="submit"
                                        disabled={state.submitting}
                                        className="btn btn-primary"
                                    >
                                        Foglalás
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}