import React from 'react';
import './PolicyStyles.css'
export default function Table(){

    return(
        <div className="py-4">
            <table className="table table-bordered border-black">
                <tbody>
                    <tr className="text-center">
                        <td className="privacy-bold">Adatkezelő</td>
                        <td className="privacy-bold">Felelősségi körök</td>
                    </tr>
                    <tr className="align-items-center">
                        <td className='privacy-description text-center'>HochTech Kft.<br /> 1134 Budapest, Lőportár utca 20/A II. em. 223.</td>
                        <td className='privacy-description text-center'>Összes tevékenység</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}