import React from 'react';
import Analytics from '../dashanalytics/Analytics'

export default function DashAnalytics(){


    return(
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="container">
                <Analytics />
            </div>
        </main>
    )
}