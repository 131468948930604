import React, {useEffect} from 'react';
import ScrollReveal from "scrollreveal";
import Mercedes from '../../assets/hero/hero-mercedes.png';
import './AboutStyles.css';
import { Link } from 'react-router-dom';

export default function AboutUs(){
    useEffect(() => {
        const sr = ScrollReveal({
          origin: 'right',
          distance: '80px',
          duration: 2000,
          reset: false // Ne állítsa vissza az animációkat a görgetés után
        });
        sr.reveal('#img-aboutus', {delay: 250, origin:'right'});
        sr.reveal('#about-title1', {delay: 300, origin:'left'});
        sr.reveal('#about-desc', {delay: 350, origin:'left'});
        sr.reveal('#btn-aboutus', {delay: 400, origin:'left'});
        return () => {
          sr.destroy();
        };
    }, [])

    return(
        <div className="aboutus py-5">
            <div className="container col-xxl-8">
                <div className="row flex-lg-row-reverse align-items-center g-1">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                        <img 
                            src={Mercedes}
                            className="d-block mx-lg-auto img-fluid" 
                            alt="Bootstrap Themes" 
                            width="700" 
                            height="500" 
                            loading="lazy"
                            id="img-aboutus"
                        />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <h1 className="display-5 text-body-emphasis lh-1 mb-3" id="about-title1">Kik vagyunk?</h1>
                        <p className="lead" style={{textAlign:'justify', marginBottom:'1.25rem'}} id="about-desc">Üdvözöljük a WestendAutoRentalnál, ahol több mint 15 éve szakmai tapasztalattal és megbízható szolgáltatásokkal várjuk ügyfeleinket. Cégünk kiemelt tevékenységi területei az autókölcsönzés, autó import és autó kereskedelem, melyeket Budapesten és Szeged vonzáskörzetében végzünk..</p>
                        <div className="button-aboutus-groupd-grid gap-2 d-md-flex justify-content-md-start" id="btn-aboutus">
                            <Link to="/contact" className="button-unique px-4 me-md-2" >Kapcsolat</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}