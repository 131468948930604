import React from 'react';

export default function Analytics(){

    return(
        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 g-3">
            <div className="col">
                <div className="card">
                    <div className="card-body d-flex justify-content-center align-items-center py-5 my-5">
                        <p>Hamarosan...</p>
                    </div>
                </div>
            </div>
            <div className="col">
                <div className="card">
                    <div className="card-body d-flex justify-content-center align-items-center py-5 my-5">
                        <p>Hamarosan...</p>
                    </div>
                </div>
            </div>
            <div className="col">
                <div className="card">
                    <div className="card-body d-flex justify-content-center align-items-center py-5 my-5">
                        <p>Hamarosan...</p>
                    </div>
                </div>
            </div>
            <div className="col">
                <div className="card">
                    <div className="card-body d-flex justify-content-center align-items-center py-5 my-5">
                        <p>Hamarosan...</p>
                    </div>
                </div>
            </div>
            <div className="col">
                <div className="card">
                    <div className="card-body d-flex justify-content-center align-items-center py-5 my-5">
                        <p>Hamarosan...</p>
                    </div>
                </div>
            </div>
            <div className="col">
                <div className="card">
                    <div className="card-body d-flex justify-content-center align-items-center py-5 my-5">
                        <p>Hamarosan...</p>
                    </div>
                </div>
            </div>
        </div>
    )
}