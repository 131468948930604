import React, {useEffect} from 'react';
import BmwImage from '../../../assets/services/Wavy_Gen-04_Single-09.jpg';
import './RentCarsStyles.css';
import ScrollReveal from 'scrollreveal'

export default function RentAllCars(){
    useEffect(() => {
        const sr = ScrollReveal({
          origin: 'right',
          distance: '80px',
          duration: 2000,
          reset: false // Ne állítsa vissza az animációkat a görgetés után
        });
        sr.reveal('#rentall-img', {delay: 250, origin:'left'});
        sr.reveal('#rentall-title-1', {delay: 300, origin:'right'});
        sr.reveal('#rentall-title-2', {delay: 330, origin:'right'});
        sr.reveal('#rentall-desc1', {delay: 370, origin:'right'});
        sr.reveal('#rentall-desc2', {delay: 400, origin:'right'});
        return () => {
          sr.destroy();
        };
    }, [])

    return(
        <div className="about-description py-5">
            <div className="container col-xxl-8">
                <div className="row flex-lg-row-reverse align-items-center g-5">
                    <div className="col-md-12 col-sm-12 col-lg-6">
                        <h3 className="rent-car-title" id="rentall-title-1">Hosszú Távú Autóbérlés:</h3>
                        <p className="lead mb-3" id="rentall-desc2" style={{textAlign:'justify', marginBottom:'1.25rem'}}>Cégünk lehetőséget kínál azoknak, akik hosszabb időre szeretnének autót bérelni. Legyen szó hónapokról vagy évekről, luxus, közép és olcsó kategóriás autóink kiemelkedő áron elérhetőek. A hosszú távú autóbérlés során a kilométer korlát nélkül élvezheti az autózást, és számíthat kiváló ár-érték arányra.</p>
                        <h3 className="rent-car-title" id="rentall-title-2">Rövid Távú Autóbérlés:</h3>
                        <p className="lead mb-3" id="rentall-desc1" style={{textAlign:'justify', marginBottom:'1.25rem'}}>Ha csak rövid időre van szüksége járműre, akkor is megtalálja nálunk a megfelelő lehetőséget. Kölcsönözze ki luxus, közép vagy olcsó kategóriás autónkat a rövid távú igényeihez, és élvezze a rugalmasságot, amit szolgáltatásunk nyújt. Kényelmes, gyors és problémamentes autóbérlés várja Önt!</p>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 order-first order-lg-last">
                        <img 
                            src={BmwImage}
                            className="d-block mx-lg-auto img-fluid" 
                            alt="Bootstrap Themes" 
                            width="700" 
                            id="rentall-img"
                            height="500" 
                            loading="lazy"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
