import Footer from "../components/footer/Footer"
import Navbar from "../components/navbar/Navbar"
import BreadCrumb from "../components/breadcrumb/BreadCrumb";
import CarImportDescription from "../components/services/carimport/CarImportDescription";
import CarImportCards from "../components/services/carimport/CarImportCards";

export default function CarImport(){
    return(
        <>
            <Navbar/>
            <BreadCrumb />
            <CarImportDescription/>
            <CarImportCards />
            <Footer />
        </>
    )
}