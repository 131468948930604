import React from 'react';
import {Link} from 'react-router-dom';
import {BsSendFill, BsTiktok, BsFacebook, BsInstagram, BsFillTelephoneFill} from 'react-icons/bs';
import { useForm, ValidationError } from '@formspree/react';
import Logo from '../../assets/logo/logo.png'
import {MdEmail} from 'react-icons/md';
import './FooterStyles.css'

export default function Footer(){
    const [state, handleSubmit] = useForm("xeqyenlp");
    if (state.succeeded) {
        return(
            <div className='card'>
                <div className='card-body'>
                    <div className="d-grid text-center text-success">
                        <h4>Sikeresen elküldte  az üzenetet!</h4>
                    </div>
                </div>
            </div>
        );
    }


    return(
        <div className="d-flex flex-column h-100 footer">
            <footer className="w-100 py-4 flex-shrink-0">
                <div className="container py-4">
                    <div className="row footer-row">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="">
                                <img 
                                    src={Logo} 
                                    alt="logo" 
                                    className="navbar-logo-img"
                                />
                                <p className="text-white footer-contact small">
                                    Tapasztalt , megbizható autókölcsönző – <br />
                                    Utazz biztonságban, válassz minket!
                                </p>
                            </div>
                            <div className='d-flex mb-2 socialsicons'>
                                <a target="_blank" href="https://www.tiktok.com/@westendautorental"><BsTiktok className='fs-3 text-white mx-2'/></a>
                                <a target="_blank" href="https://www.facebook.com/profile.php?id=61556163058332"><BsFacebook className='fs-3 text-white mx-2'/></a>
                                <a target="_blank" href="https://www.instagram.com/westendautorental/"><BsInstagram className='fs-3 text-white mx-2 '/></a>
                            </div>
                            <div className="d-grid mb-3 footer-contact">
                                <span className='small text-white'><span><MdEmail /></span> info@westendautorental.hu</span>
                                <span className='small text-white'><span><BsFillTelephoneFill /></span> +36 20 506 4321</span>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-12">
                            <h5 className="footer-title mb-3">Navigáció</h5>
                            <ul className="list-unstyled text-muted footer-policy">
                                <li>
                                    <Link className="footer-links" to='/'>Főoldal</Link>
                                </li>
                                <li>
                                    <Link className="footer-links" to='/rentcar'>Autóbérlés</Link>
                                </li>
                                <li>
                                    <Link className="footer-links" to='/services'>Szolgáltatások</Link>
                                </li>
                                <li>
                                    <Link className="footer-links" to='/aboutus'>Rólunk</Link>
                                </li>
                                <li>
                                    <Link className="footer-links" to='/contact'>Kapcsolat</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-12">
                            <h5 className="footer-title mb-3">Szolgáltatások</h5>
                            <ul className="list-unstyled text-muted footer-policy">
                                <li></li>
                                <li><Link to="/carrent" className='footer-links'>Autó bérlés</Link></li>
                                <li><Link to="/cartrade" className='footer-links'>Gépjármű kereskedelem</Link></li>
                                <li><Link to="/carimport" className='footer-links'>Autó import</Link></li>
                                <li><Link to="/fullmanagement" className='footer-links'>Teljes körű ügyintézés</Link></li>
                                <li><Link to="/carinsurance" className='footer-links'>Biztosítás kötés</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <h5 className="footer-title text-white mb-3">Hírlevél</h5>
                            <p className="small text-white">Ne maradj le a legfrissebb autóbérlési információkról - iratkozz fel hírlevelünkre!</p>
                            <form onSubmit={handleSubmit}>
                                <div className="input-group mb-3">
                                    <input 
                                        className="form-control text-black" 
                                        type="text" 
                                        placeholder="Email cím"  
                                        aria-describedby="button-addon2" 
                                        name="email"
                                    />
                                    <ValidationError 
                                        prefix="Email" 
                                        field="email"
                                        errors={state.errors}
                                    />
                                    <button className="btn btn-primary" id="button-addon2" type="submit" disabled={state.submitting}>
                                        <BsSendFill />
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="d-grid d-lg-flex d-md-flex d-sm-grid justify-content-center justify-content-sm-center justify-content-lg-between justify-content-md-between my-1 text-center">
                        <span className="small text-center text-white">&copy; Copyright 2024. Minden jog fenntartva. </span>
                        <Link className='footer-links' to="/privacypolicy">Adatvédelmi szabályzat</Link>
                    </div>
                </div>
            </footer>
        </div>
    )
}