import React, {useEffect} from 'react';
import RentImage from '../../../assets/services/7197355.jpg';
import './RentCarsStyles.css';
import ScrollReveal from 'scrollreveal'
import { Link } from 'react-router-dom';

export default function RentalCars(){
    useEffect(() => {
        const sr = ScrollReveal({
          origin: 'right',
          distance: '80px',
          duration: 2000,
          reset: false // Ne állítsa vissza az animációkat a görgetés után
        });
        sr.reveal('#rental-cars-img', {delay: 250, origin:'right'});
        sr.reveal('.rental-cars-title', {delay: 300, origin:'left'});
        sr.reveal('#rental-desc', {delay: 350, origin:'left'});
        sr.reveal('#rental-btn', {delay: 400, origin:'left'});
        return () => {
          sr.destroy();
        };
    }, [])

    return(
        <div className="rental-cars py-5">
            <div className="container col-xxl-8">
                <div className="row flex-lg-row-reverse align-items-center g-5">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                        <img 
                            src={RentImage}
                            className="d-block mx-lg-auto img-fluid" 
                            alt="Bootstrap Themes" 
                            width="700" 
                            height="500" 
                            loading="lazy"
                            id="rental-cars-img"
                        />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <h1 className="rent-car-title rental-cars-title">Autóbérlés Hosszú / Rövid Távra</h1>
                        <p style={{textAlign:'justify', marginBottom:'1.25rem'}} className="lead mb-3" id="rental-desc" >Üdvözöljük a WestendAutoRental Autóbérlés szolgáltatásainak oldalán! Rugalmas és kényelmes autókölcsönzési lehetőségeinkkel mindig az Ön szolgálatában állunk. Kínálatunkban széles választékban találhatók autóink, amelyek megfelelnek az Ön igényeinek és elvárásainak. Az alábbiakban ismertetjük a hosszú és rövid távra szóló autóbérlési szolgáltatásainkat:</p>
                        <div className="button-aboutus-groupd-grid gap-2 d-md-flex justify-content-md-start" id="rental-btn">
                            <Link to="/contact" className="button-unique px-4 me-md-2 text-decoration-none">Kapcsolat</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

