import React, {useEffect} from "react";
import ScrollReveal from "scrollreveal";
import HeroImage from '../../assets/hero/hero-mercedes.png';
import './RentCarStyles.css';

export default function RentCar(){
    useEffect(() => {
        const sr = ScrollReveal({
          origin: 'right',
          distance: '80px',
          duration: 2000,
          reset: false
        });
        sr.reveal('.rent-car-title', {delay: 200, origin:'left'});
        sr.reveal('.rentcar-description', {delay: 300, origin:'left'});
        sr.reveal('#img-rentcar', {delay: 300, origin:'right'});
        sr.reveal('#rent--car-btn', {delay: 350, origin:'left'});
        sr.reveal('#rent--car-btn1', {delay: 400, origin:'left'});
        return() => {
            sr.destroy();
        }
    }, []);

    return(
        <div className="rent-car py-5">
            <div className="container col-xxl-8">
                <div className="row flex-lg-row-reverse align-items-center g-5">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                        <img 
                            src={HeroImage }
                            className="d-block mx-lg-auto img-fluid" 
                            alt="Bootstrap Themes" 
                            width="700" 
                            id="img-rentcar"
                            height="500" 
                            loading="lazy"
                        />
                    </div>
                    <div className="col-lg-6 col-sm-12 col-md-12">
                        <p className="lead rentcar-description">Válassza autóinkat korlátlan kilométerrel, és élvezze az abszolút szabadságot az úton! Fedezze fel úti célját bárhova vezető úton, anélkül, hogy aggódnia kellene a megtett kilométerek miatt. Rugalmas árak, gyors és egyszerű foglalás - a szabadság érzése vár rád, úgyhogy induljon velünk! Ne hagyja, hogy a távolság korlátozza kalandját, és érezze magát otthon az úton, miközben felfedezi az ismeretlen tájakat. Bízza ránk az utazás részleteit, és élvezze a gondtalan autókölcsönzés minden előnyét. Legyen az út a cél, és érezze meg a szabadság szelét a kilométerek korlátja nélkül!</p> 
                    </div>
                </div>
            </div>
        </div>
    )
}