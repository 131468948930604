import React from 'react';
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';
import AboutUs from '../components/aboutus/AboutUs';
import AboutDescrpiton from '../components/aboutus/AboutDescrpiton';
import AbAccordion from '../components/accordion/AbAccordion';
import BreadCrumb from "../components/breadcrumb/BreadCrumb";
export default function About(){


    return(
        <>
            <Navbar />
            <BreadCrumb />
            <AboutUs />
            <AboutDescrpiton />
            <AbAccordion />
            <Footer />
        </>
    )
}