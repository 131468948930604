import React, {useEffect} from 'react';
import HeroImage from '../../../assets/services/happy-customer-buying-new-car-dealership.jpg';
import './FullAdministrationStyles.css';
import ScrollReveal from 'scrollreveal';
import { Link } from 'react-router-dom';
export default function FullAdDescription(){
    useEffect(() => {
        const sr = ScrollReveal({
          origin: 'right',
          distance: '80px',
          duration: 2000,
          reset: false
        });
        sr.reveal('#img-fulladdesc', {delay: 250, origin:'right'});
        sr.reveal('#fulladddesc-title', {delay: 250, origin:'left'});
        sr.reveal('#fulladddesc-desc', {delay: 300, origin:'left'});
        sr.reveal('#fulladddesc-desc1', {delay: 330, origin:'left'});
        sr.reveal('#fulladddesc-desc2', {delay: 370, origin:'left'});
        sr.reveal('#btn-fulladddesc', {delay: 400, origin:'left'});
        return() => {
            sr.destroy();
        }
    }, []);

    return(
        <div className="full-add-description py-5">
            <div className="container col-xxl-8">
                <div className="row flex-lg-row-reverse align-items-center g-5">
                    <div className="col-12 col-md-12 col-sm-12 col-lg-6">
                        <img 
                            src={HeroImage}
                            className="d-block mx-lg-auto img-fluid rounded" 
                            alt="Bootstrap Themes" 
                            width="700" 
                            height="500" 
                            loading="lazy"
                            id="img-fulladdesc"
                        />
                    </div>
                    <div className="col-lg-6 col-sm-12 col-md-12 ">
                    <p className="lead" style={{textAlign:'justify', marginBottom:'1.25rem'}} id="fulladddesc-desc"><span>Vizsgáztatás és Engedélyeztetés: </span>Gondoskodunk a vizsgáztatásról és a forgalmi engedélyeztetésről, hogy autója jogszabályoknak megfelelően kerüljön forgalomba.</p>
                        <p className="lead" style={{textAlign:'justify', marginBottom:'1.25rem'}} id="fulladddesc-desc1"><span>Értesítés és További Tanácsadás: </span>Önt mindvégig értesítjük a folyamat állapotáról, és szükség esetén további tanácsadást nyújtunk.</p>
                        <p className="lead" style={{textAlign:'justify', marginBottom:'1.25rem'}} id="fulladddesc-desc2">Ne hagyja figyelmen kívül autója jogszabályoknak való megfelelőségét! Válassza szolgáltatásainkat, és biztosítsa autója biztonságos és zavartalan közlekedését Magyarországon!</p>
                        <div className="button-aboutus-groupd-grid gap-2 d-md-flex justify-content-md-start" id="btn-fulladddesc">
                            <Link to="/contact" className="button-unique px-4 me-md-2 text-decoration-none" >Kapcsolat</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}