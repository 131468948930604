import React, {useEffect} from 'react';
import HeroImage from '../../../assets/services/20945490.jpg';
import './CarTradeStyles.css'
import ScrollReveal from 'scrollreveal';
import { Link } from 'react-router-dom';

export default function CarChange(){
    useEffect(() => {
        const sr = ScrollReveal({
          origin: 'right',
          distance: '80px',
          duration: 2000,
          reset: false
        });
        sr.reveal('#img-carchange', {delay: 250, origin:'right'});
        sr.reveal('#carchange-title', {delay: 300, origin:'left'});
        sr.reveal('#carchange-desc', {delay: 350, origin:'left'});
        sr.reveal('#carchange-btn', {delay: 400, origin:'left'});
        return() => {
            sr.destroy();
        }
    }, []);

    return(
        <div className="consulation py-5">
            <div className="container col-xxl-8">
                <div className="row flex-lg-row-reverse align-items-center g-5">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                        <img 
                            src={HeroImage}
                            className="d-block mx-lg-auto img-fluid" 
                            alt="" 
                            width="700" 
                            height="500" 
                            loading="lazy"
                            id="img-carchange"
                        />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <h1 className="display-5 text-body-emphasis lh-1 mb-3" id="carchange-title">Csere és beszámítás</h1>
                        <p className="lead" style={{textAlign:'justify', marginBottom:'1.25rem'}} id="carchange-desc">Amennyiben Ön új jármű vásárlását tervezi, vállaljuk a régi autója beszámítását. Nálunk történő vásárlás esetén piaci árhoz közeli ajánlatot teszünk járműve megvásárlására.</p>
                        <Link to='/contact' id="carchange-btn shadow-sm" className='button-unique'>Kapcsolat</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}