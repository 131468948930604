import React, { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { db, storage } from '../../firebase';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import './DashStyles.css';

export default function DashAddPeople({ togglePopup }) {
    const [imageUpload, setImageUpload] = useState([]);
    const [week, setWeek] = useState('');
    const [caraddres, setCarAddres] = useState('');
    const [name, setName] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [vintage, setVintage] = useState('');
    const [fuel, setFuel] = useState('');
    const [cosumption, setCosumption] = useState('');
    const [aircod, setAircod] = useState('');
    const [gearbox, setGearbox] = useState('');
    const [control, setControl] = useState('');
    const [dbpeople, setDbpeople] = useState('');
    const [description, setDescription] = useState('');
    const [dayrent, setDayRent] = useState('');
    const [monthrent, setMonthRent] = useState('');
    const [selectedCollection, setSelectedCollection] = useState('archive');
    const [isUploadSuccess, setIsUploadSuccess] = useState(false);


    const handleImageChange = (e) => {
        const files = e.target.files;
        const uploadedImages = [];
        for (let i = 0; i < files.length; i++) {
          uploadedImages.push(files[i]);
        }
        setImageUpload(uploadedImages);
    };

    const handleAdd = async (e) => {
        e.preventDefault();
         try {
            if (imageUpload.length > 0) {
                const uploadTasks = imageUpload.map((file) => {
                const storageRef = ref(storage, `images/${file.name}`);
                const uploadTask = uploadBytesResumable(storageRef, file);
                return new Promise((resolve, reject) => {
                    uploadTask.on(
                        'state_changed',
                        (snapshot) => {},
                        (error) => {
                            console.error('Error uploading image:', error);
                            reject(error);
                        },
                        async () => {
                            const downloadURL = await getDownloadURL(
                              uploadTask.snapshot.ref
                            );
                            resolve(downloadURL);
                        }
                    );
                });
            });
            const downloadURLs = await Promise.all(uploadTasks);
            const docRef = await addDoc(collection(db, selectedCollection), {
                name: name,
                caraddres: caraddres,
                state: state,
                country: country,
                vintage: vintage,
                fuel: fuel,
                cosumption: cosumption,
                aircod: aircod,
                gearbox: gearbox,
                control: control,
                dbpeople: dbpeople,
                description: description,
                dayrent: dayrent,
                week: week,
                monthrent: monthrent,
                imageUrls: downloadURLs, 
                timeStamp: serverTimestamp(),
            });

            console.log('Document added with ID:', docRef.id);
            setIsUploadSuccess(true);
        } else {
        const docRef = await addDoc(collection(db, selectedCollection), {
            name: name,
            caraddres: caraddres,
            state: state,
            country: country,
            vintage: vintage,
            fuel: fuel,
            cosumption: cosumption,
            aircod: aircod,
            gearbox: gearbox,
            control: control,
            dbpeople: dbpeople,
            description: description,
            dayrent: dayrent,
            week: week,
            monthrent: monthrent,
            timeStamp: serverTimestamp(),
        });
            console.log('Document added with ID:', docRef.id);
            setIsUploadSuccess(true);
        }
        } catch (err) {
          console.error('Error:', err);
        }
    };

    const handleNameChange = (e) => {
      setName(e.target.value);
    };
    
    const handleStateChange = (e) => {
      setState(e.target.value);
    };
    
    const handleCountryChange = (e) => {
      setCountry(e.target.value);
    };
  
    const handleVintageChange = (e) => {
        setVintage(e.target.value);
    };
  
    const handleFuelChange = (e) => {
        setFuel(e.target.value);
    };
  
    const handleCosumptionChange = (e) => {
        setCosumption(e.target.value);
    };
  
    const handleAirCodChange = (e) => {
        setAircod(e.target.value);
    };
  
    const handleGearboxChange = (e) => {
        setGearbox(e.target.value);
    };
  
    const handleControlChange = (e) => {
        setControl(e.target.value);
    };
      
    const handleDbPeopleChange = (e) => {
        setDbpeople(e.target.value);
    };
  
    const handleCollectionChange = (e) => {
        setSelectedCollection(e.target.value);
    };
  
    const handleDayRentChange = (e) => {
        setDayRent(e.target.value);
    };
  
    const handleMonthRentChange = (e) => {
        setMonthRent(e.target.value);
    };
  
    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handleWeekChange = (e) => {
        setWeek(e.target.value);
    };
  
    const handleCarAddresChange = (e) => {
        setCarAddres(e.target.value);
    };

    return (
        <div className='modal-background-1 py-5 overflow-y-auto'>
            <div className='container'>
                <div className='scrollable-content overflow-auto mt-5 card-over-2' style={{ paddingBottom:"200px", paddingTop:'800px'}}>
                    <button onClick={togglePopup} className='btn btn-primary my-2' style={{ float: 'right' }}>
                        <AiOutlineClose />
                    </button>
                <div className='card card-dashadd py-5'>
                    {isUploadSuccess && (
                      <div className='alert alert-success mt-2 text-center w-100'>
                        <p className='fw-bolder'>Sikeresen feltöltötted az adatokat!</p>
                      </div>
                    )}
                    <form onSubmit={handleAdd} className='card-body'>
                        <div className='row g-2'>
                            <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                <input
                                    type='file'
                                    className='form-control'
                                    onChange={handleImageChange}
                                    multiple  // Allow multiple file selection
                                />
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                <input className='form-control' type='text' placeholder='Cím' value={caraddres} onChange={handleCarAddresChange} />
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                <input className='form-control' type='text' placeholder='Márka' value={name} onChange={handleNameChange} />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <input className='form-control' type="text" placeholder="Modell" value={state} onChange={handleStateChange} />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <input className='form-control' type="text" placeholder='kaukció' value={country} onChange={handleCountryChange} />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <input className='form-control' type="text" placeholder='napi bérlési ár' value={dayrent} onChange={handleDayRentChange} />
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                <input className='form-control' type='text' placeholder='Heti bérlési ár' value={week} onChange={handleWeekChange} />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <input className='form-control' type="text" placeholder='Havi bérlési ár' value={monthrent} onChange={handleMonthRentChange} />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <input className='form-control' type="text" placeholder='Évjárat' value={vintage} onChange={handleVintageChange} />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <input className='form-control' type="text" placeholder='Üzemanyag' value={fuel} onChange={handleFuelChange} />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <input className='form-control' type="text" placeholder='Fogyasztás' value={cosumption} onChange={handleCosumptionChange} />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <input className='form-control' type="text" placeholder='Klíma' value={aircod} onChange={handleAirCodChange} />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <input className='form-control' type="text" placeholder='Váltó' value={gearbox} onChange={handleGearboxChange} />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <input className='form-control' type="text" placeholder='Tempomat' value={control} onChange={handleControlChange} />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <input className='form-control' type="text" placeholder='Szállítható személyek száma' value={dbpeople} onChange={handleDbPeopleChange} />
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                <select className='form-control' value={selectedCollection} onChange={handleCollectionChange}>
                                    <option value='archive'>Válassz Kollekciót</option>
                                    <option value='olcso'>olcsó</option>
                                    <option value='kozep'>Közep</option>
                                    <option value='felso'>Felső</option>
                                    <option value='minibus'>Kisbusz</option>
                                    <option value='truks'>Teherautó</option>
                                </select>
                                </div>
                                    <div className='col-12'>
                                        <textarea
                                        className='form-control w-100'
                                        style={{
                                            maxHeight: '300px',
                                            minHeight: '200px',
                                        }}
                                        value={description}
                                        onChange={handleDescriptionChange}
                                        ></textarea>
                                    </div>
                                </div>
                            <button type='submit' className='btn btn-primary mt-2'>Hozzáadás</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}