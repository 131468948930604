import React, { useContext, useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import {auth} from '../../firebase';
import { AuthContext } from "../../context/AuthContext";
import { Link } from "react-router-dom";
import Logo from '../../assets/logo/logo.png'
import './SingInStyles.css';

export default function SingIn() {
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const {dispatch} = useContext(AuthContext)

  const handlelogin = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;
      dispatch({type:"LOGIN", payload:user})
      navigate('/dashboard')
    })
    .catch((error) => {
      console.error("Bejelentkezési hiba", error)
      setError(true);
    });
  }   
  
  return (
    <div className="login-container">
      <div className="login-form">
        <div className="d-flex justify-content-center">
          <Link to="/">
            <img 
              src={Logo}
              alt="" 
              className="login-logo"
            />
          </Link>
        </div>
        <h3 className="fw-bolder text-center">Bejelentkezés</h3>
        <form onSubmit={handlelogin}>
          <div className="form-floating mb-3">
            <input 
              type="email"
              onChange={e => setEmail(e.target.value)}
              className="form-control"
              id="floatingInput" 
            />
            <label>Email cím</label>
          </div>
          <div className="form-floating mb-3">
            <input 
              className="form-control"
              id="floatingInput" 
              type="password"
              onChange={e => setPassword(e.target.value)}
            />
            <label>Jelszó</label>
          </div>
          <button className="btn btn-primary w-100" type="submit">Bejelentkezés</button>
        </form>
        <div className="d-flex justify-content-center align-items-center my-2">
          {error && <span className="text-danger text-center fw-bolder">Hibás jelszó vagy email!</span>}
        </div>
      </div>
    </div>
  );
}