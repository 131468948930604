import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import SendMessageImage from '../../assets/contact/Files sent-rafiki.png'
export default function ComForm(){
    const [state, handleSubmit] = useForm("xleqglve");
    if (state.succeeded) {
        return(
            <div className='card'>
                <img className='img-fluid' style={{height:'70%'}} src={SendMessageImage} alt="" />
                <div className='card-body'>
                    <div className="d-grid text-center text-success">
                        <h4>Sikeresen elküldte  az üzenetet!</h4>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row g-2">
                <div className="col" id="name-form">
                    <div className="form-floating shadow-sm">
                        <input 
                            type="text" 
                            className="form-control" 
                            id="floatingInputValue" 
                            placeholder="name@example.com" 
                            name="name"
                        />
                        <label className="contactform-lab" htmlFor="floatingInputValue">Teljes Név:</label>
                        <ValidationError 
                            prefix="name" 
                            field="name"
                            errors={state.errors}
                        />
                    </div>
                </div>
                <div className="col" id="phone-form">
                    <div className="form-floating shadow-sm">
                        <input 
                            placeholder='pl: +3620-425-6655'
                            type="text" 
                            className="form-control" 
                            id="floatingInputValue" 
                            name="phone"
                        />
                        <label className="contactform-lab" htmlFor="floatingInputValue">Telefonszám:</label>
                        <ValidationError 
                            prefix="phone" 
                            field="phone"
                            errors={state.errors}
                        />
                    </div>
                </div>
            </div>
            <div>
                <div className="form-floating shadow-sm" id="email-form">
                    <input 
                        placeholder='pl: info@gmail.com'
                        type="text" 
                        className="form-control" 
                        id="floatingInputValue" 
                        name="email"
                    />
                    <label className="contactform-lab" htmlFor="floatingInputValue">Email cím:</label>
                    <ValidationError 
                        prefix="Email" 
                        field="email"
                        errors={state.errors}
                    />
                </div>
            </div>
            <div className="mb-3" id="message-form">
                <div className="form-floating shadow-sm">
                    <textarea 
                        className="form-control" 
                        placeholder="Hagyj üzenetet itt" 
                        id="floatingTextarea2" 
                        name="message"
                        style={{
                            minHeight:'285px',
                            maxHeight:'300px'
                        }}
                    ></textarea>
                    <label className="contactform-lab" htmlFor="floatingTextarea2">Üzenet:</label>
                    <ValidationError 
                        prefix="Message" 
                        field="message"
                        errors={state.errors}
                    />
                </div>
            </div>
            <div className="btn-form">
                <button type='submit' className='button-unique shadow-sm' id='contactform-btn' disabled={state.submitting}>Küldés</button>
            </div>
        </form>
    );
}