import React, {useEffect} from 'react';
import ScrollReveal from 'scrollreveal'
import RentDescImage from '../../../assets/services/20945913.jpg';
import './RentCarsStyles.css';
import { Link } from 'react-router-dom';

export default function RentUniqueOffer(){
    useEffect(() => {
        const sr = ScrollReveal({
          origin: 'right',
          distance: '80px',
          duration: 2000,
          reset: false // Ne állítsa vissza az animációkat a görgetés után
        });
        sr.reveal('#rentunique-img', {delay: 250, origin:'right'});
        sr.reveal('#rentunique-desc-1', {delay: 300, origin:'left'});
        sr.reveal('#rentunique-desc-2', {delay: 350, origin:'left'});
        sr.reveal('#rentunique-btn', {delay: 400, origin:'left'});
        return () => {
          sr.destroy();
        };
    }, [])

    return(
        <div className="rentunique-offer py-5">
            <div className="container col-xxl-8">
                <div className="row flex-lg-row-reverse align-items-center g-5">
                    <div className="col-12 col-sm-12 col-sm-12 col-lg-6">
                        <img 
                            src={RentDescImage}
                            className="d-block mx-lg-auto img-fluid" 
                            alt="Bootstrap Themes" 
                            width="700" 
                            height="500" 
                            loading="lazy"
                            id="rentunique-img"
                        />
                    </div>
                    <div className="col-lg-6 col-sm-12 col-md-12">
                        <p className="lead mb-3" id="rentunique-desc-1" style={{textAlign:'justify', marginBottom:'1.25rem'}}>Egyéni ajánlatért, foglalásért kérjük, lépjen kapcsolatba ügyfélszolgálatunkkal. Kattintson itt az elérhetőségeink megtekintéséhez.</p>
                        <p className="lead mb-3" id="rentunique-desc-2" style={{textAlign:'justify', marginBottom:'1.25rem'}}>Válassza WestendAutoRental Autóbérlés szolgáltatásait, és tapasztalja meg a minőségi járművek és kiváló szolgáltatás kombinációját!</p>
                        <Link to="/contact" className="button-unique px-4" id="rentunique-btn">Kapcsolat</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
