import React, { useEffect, useState } from 'react';
import './BreadCrumbStyles.css'
import { Link, useLocation } from 'react-router-dom';

const routeNameMapping = {
    rentcar: 'Kollekció',
    aboutus: 'Rólunk',
    contact: 'Kapcsolat',
    kozep: 'Középkategóriás autók',
    olcso:'Olcsó autók',
    truks:'Teherautó',
    minibus:'Kisbusz',
    felso:'Felsőkategóriás autók',
    privacypolicy: 'Adatvédelmi szabályzat',
    rentcars: 'Autó bérlés',
    carimport: 'Autó import',
    carrent: 'Autó bérlés',
    cartrade: 'Autó kereskedés',
    fullmanagement: 'Teljeskörű ügyintézés',
    carinsurance: 'Biztosítás kötés'
};

export default function BreadCrumb() {
    const location = useLocation();
    const paths = location.pathname.split('/').filter((path) => path !== '');
    const [pageTitle, setPageTitle] = useState('');

    useEffect(() => {
        const currentPath = paths[paths.length - 1];
        setPageTitle(routeNameMapping[currentPath] || currentPath);
    }, [location.pathname]);

    return (
        <div className="breadcrumb-component py-5">
            <div className="container py-5 d-flex align-items-center">
                <nav aria-label="breadcrumb-card">
                    <h1 className="breadcrumb-title">{routeNameMapping[paths[paths.length - 1]] || paths[paths.length - 1]}</h1>
                    <ol className="breadcrumb py-5 my-lg-0 my-md-0 my-sm-1 my-2">
                        <li className="breadcrumb-item mx-1">
                            <Link className='breadcrumb-link' to="/">Főoldal</Link>
                        </li>
                        {paths.map((path, index) => (
                            <li key={path} className="breadcrumb-item mx-1">
                                {index === paths.length - 1 ? (
                                    <span className='breadcrumb-list mx-1' aria-current="page">{routeNameMapping[path] || path}</span>
                                ) : (
                                    <span className='breadcrumb-list'>
                                        <Link 
                                            style={{color:'#1a1a1a', textDecoration:'none'}}
                                            to={`/${paths.slice(0, index + 1).join('/')}`}
                                        >{routeNameMapping[path] || path}</Link>
                                    </span>
                                )}
                            </li>
                        ))}
                    </ol>
                </nav>
            </div>
        </div>
    );
}

