import BreadCrumb from "../components/breadcrumb/BreadCrumb"
import Footer from "../components/footer/Footer"
import Navbar from "../components/navbar/Navbar"
import CarBuy from "../components/services/cartrade/CarBuy"
import CarChange from "../components/services/cartrade/CarChange"
import CarSale from "../components/services/cartrade/CarSale"
import CarsTrade from '../components/services/cartrade/CarsTrade'

export default function CarTrade(){
    return(
        <>
            <Navbar/>
            <BreadCrumb />
            <CarsTrade />
            <CarBuy />
            <CarSale />
            <CarChange />
            <Footer/>
        </>
    )
}