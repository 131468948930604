import React from "react"
import { Link } from "react-router-dom"

export default function NotFound(){

    return(
        <div className="not-found py-5">
            <div className="container py-5 text-center">
                <h1 className="found-title">404 - Az oldal nem található</h1>
                <p className="found-description">Az általad keresett oldal nem található.</p>
                <Link to="/" className="button-unique">Vissza a Főoldalra</Link>
            </div>
        </div>
    )
}