import React from 'react';
import Policy from '../components/policy/Policy';
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';
import BreadCrumb from '../components/breadcrumb/BreadCrumb';

export default function PrivacyPolicy(){
    return(
        <>
            <Navbar />
            <BreadCrumb />
            <Policy />
            <Footer />
        </>
    )
}