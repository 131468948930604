import React, {useState, useEffect} from 'react';
import {BsFillCalendarEventFill} from 'react-icons/bs'
import {BiSolidPhoneCall} from 'react-icons/bi'
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import Book from '../booking/Book'
import Sliders from './Sliders';
import './CarDetailStyles.css';
import ScrollReveal from 'scrollreveal';

export default function CarDetail({product, productId, setProduct, collectionId}){
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
        setIsOpen(!isOpen);  
    };

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                if (!collectionId || !productId) {
                console.error('Invalid collectionId or productId');
                return;
            }
            const docRef = doc(db, collectionId, productId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setProduct({ id: docSnap.id, ...docSnap.data() });
            } else {
                console.error('Product not found');
            }
            } catch (error) {
                console.error('Error fetching product:', error.message);
          }
        };
        fetchProduct();
    }, [collectionId, productId]);

    if (!product) {
        return <div>Loading...</div>;
    }

    return(
        <div className="cardetail py-5">
            <div className="container py-5">
            <h3 className="cardetail-information py-3">Részletes információk</h3>
                <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2">
                    <div className="col">
                        <div className="carousel slide" data-ride="carousel" id="carousel-1">
                            <div className="carousel-inner" role="listbox">
                                <Sliders imageUrls={product.imageUrls} />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <h2 className="cardetail-name">{product.caraddres}</h2>
                        <div className="cardetail-description">
                            <p>{product.description}</p>
                        </div>
                        <div className="d-grid price">
                            <span className="cardetail-day-price mr-2">
                                Napi bérleti díj: <span className="cardetail-been"> {product.dayrent} Ft</span> / nap
                            </span>
                            <span className="cardetail-day-month mr-2">
                                Havi bérleti díj: <strong className="cardetail-been"> {product.monthrent} Ft</strong> / hónap
                            </span>
                            <p className="cardetail-day-saleat mr-2">
                                kaukció: <span className="cardetail-been"> {product.country}</span> Ft
                            </p>
                        </div>
                        <div className="d-flex justify-content-between my-3">
                            <div className="cardetail-left">
                                <p className="cardetail-data-left" id="cardetail-data1">Évjárat</p> 
                                <p className="cardetail-data-left" id="cardetail-data4">Üzemanyag: </p>    
                                <p className="cardetail-data-left" id="cardetail-data5">Fogyasztás: </p>    
                                <p className="cardetail-data-left" id="cardetail-data6">Klíma: </p>    
                                <p className="cardetail-data-left" id="cardetail-data7">Váltó: </p>    
                                <p className="cardetail-data-left" id="cardetail-data8">Tempomat:</p>  
                                <p className="cardetail-data-left" id="cardetail-data9">Szállítható személyek száma:</p>    
                            </div>
                            <div className="cardetail-right w-50">
                                <p className='cardetail-data-right' id='cardetail-data1'>{product.vintage}</p>       
                                <p className='cardetail-data-right' id='cardetail-data4'>{product.fuel}</p>    
                                <p className='cardetail-data-right' id='cardetail-data5'>{product.cosumption}</p> 
                                <p className='cardetail-data-right' id='cardetail-data6'>{product.aircod}</p>    
                                <p className='cardetail-data-right' id='cardetail-data7'>{product.gearbox}</p>    
                                <p className='cardetail-data-right' id='cardetail-data8'>{product.control}</p>    
                                <p className='cardetail-data-right' id='cardetail-data9'>{product.dbpeople}</p>   
                            </div>
                        </div>
                        <div className="d-flex">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item cardetail-list">Külföldre való utazás esetén 30% felár + Kötelező utasbiztosítás</li>
                                <li className="list-group-item cardetail-list">áraink tájékoztató jellegűek, nem minősülnek ajánlattételnek</li>
                                <li className="list-group-item cardetail-list">A megadott bérleti feltételek minden szerződéskötésnél változhatnak</li>
                                <li className="list-group-item cardetail-list">Ha most előre fizet 3 hónapos bérlésért, akkor élvezheti a teljes díj 15%-os kedvezményét!</li>
                                <li className="list-group-item cardetail-list">Egyedi árajánlatért keressen bizalommal</li>
                                <li className="list-group-item cardetail-list">A kaukció 30 napig visszatartásra kerül</li>
                                <li className="list-group-item cardetail-list">Országos autópálya matricával ellátva</li>
                                <li className="list-group-item cardetail-list">Km korlátozás nincs</li>
                            </ul>
                        </div>
                        <div className="mt-3 text-center cardetail-booking">
                            <a 
                                href="tel:+36205064321" 
                                className="button-unique-2 mx-1 text-decoration-none"
                            ><BiSolidPhoneCall /> Hívás</a>
                            <button onClick={toggleMenu} className="button-unique mx-1"><BsFillCalendarEventFill /> Foglalás</button>
                        </div>
                        {isOpen && (
                            <Book car={product} toggleMenu={toggleMenu} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}