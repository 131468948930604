import React from 'react';
import './ContactStyles.css';

export default function ContactMap(){
    return(
        <div className="contact-map">
            <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d172572.20520744674!2d18.965493043277306!3d47.48132742541838!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741c334d1d4cfc9%3A0x400c4290c1e1160!2sBudapest!5e0!3m2!1shu!2shu!4v1697108553146!5m2!1shu!2shu" 
                style={{
                    border:'0',
                    width:'100%',
                    height:'450px',
                    filter: 'grayscale(100%) invert(100%)',
                }} 
                allowFullScreen={true} 
                referrerPolicy="no-referrer-when-downgrade"
                loading="lazy" 
            ></iframe>
        </div>
    )
}