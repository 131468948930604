import React from "react";
import './PolicyStyles.css'
export default function PolicyText(){
    return(
        <div>
            <div>
                <div className="py-4">
                    <h5 className="privacy-title">Adattulajdonosi jogok és jogérvényesítés</h5>
                </div>
                <p className="privacy-description">Amennyiben nem szeretné személyes adatai kereskedelmi ajánlatok közlésére történő felhasználását, joga van mindezt indoklás nélkül megtiltani. Törlési kérelmét írásban szükséges megküldenie az Adatkezelő részére, e-mailben vagy postai levél formájában: HochTech Kft. , 1134 Budapest, Lőportár utca 20/A II. em. 223.  vagy info@westendautorental.hu </p>
            </div>
            <div>
                <div className="py-4">
                    <h5 className="privacy-title">Kiskorúak és korlátozottan cselekvőképes személyes adatainak védelme</h5>
                </div>
                <p className="privacy-description"><span className="privacy-bold">HochTech Kft.</span> internetes weboldala, valamint megjelenéseinek tartalma többnyire nem 13 év alatti kiskorúak részére szól. Nem kezelünk és nem gyűjtünk 13 év alatti gyermekekről személyes adatokat igazolható szülői vagy gondviselői hozzájárulás nélkül. Szülei vagy gondviselői kérhetik a gyermekre vonatkozó adatok kiadását, törlését. Ha az Adatkezelő számára az adatkezelés, adatfeldolgozás folyamán válik nyilvánvalóvá az életkor, abban az esetben a szülői hozzájárulás beszerzése céljából az adatok felhasználhatók. Törvényes képviselő hozzájárulása szükséges cselekvőképtelen és korlátozottan cselekvőképes kiskorú személy nyilatkozatához, kivéve a mindennapi életben tömegesen előforduló regisztrációt célzó és különösebb megfontolást nem igénylő szolgáltatásrészeket.</p>
            </div>
            <div>
                <div className="py-4">
                    <h5 className="privacy-title">Cookie-k (sütik) kezelése</h5>
                </div>
                <p className="privacy-description">A Cookie nyilatkozatban olvashat bővebben a sütik (cookie-k) kezeléséről: Ez a weboldal sütiket használ annak érdekében, hogy javítsa a felhasználói élményt és személyre szabja a tartalmakat. A sütik kis adatfájlok, amelyeket az Ön eszközére helyezünk el a weboldalunk böngészése során. Az alábbiakban részletesen leírjuk, milyen típusú sütiket használunk és hogyan kezelheti azokat.</p>
                <div className="py-1">
                    <ol className='privacy-list'>
                        <li>
                            <span className="privacy-bold">Milyen sütiket használunk?</span>
                            <p className="privacy-description">A weboldalunkon használt sütiket két fő típusra lehet osztani: működéshez szükséges sütik és analitikai sütik. A működéshez szükséges sütik nélkülözhetetlenek a weboldal alapvető funkcionalitásaihoz, míg az analitikai sütik segítenek nekünk a weboldal teljesítményének és használatának elemzésében.</p>
                        </li>
                        <li>
                            <span className="privacy-bold">Hogyan használjuk fel a sütiket?</span>
                            <p className="privacy-description">A sütik által gyűjtött információkat kizárólag weboldalunk fejlesztéséhez és statisztikai célokra használjuk fel. Nem osztjuk meg ezeket az információkat harmadik féllel.</p>
                        </li>
                        <li>
                            <span className="privacy-bold">Hogyan lehet kezelni a sütiket?</span>
                            <p className="privacy-description">Böngészője beállításaiban lehetősége van kezelni és letiltani a sütiket. Kérjük, vegye figyelembe, hogy bizonyos sütik nélkülözhetetlenek a weboldalunk megfelelő működéséhez, és azok letiltása esetén előfordulhat, hogy bizonyos funkciók nem lesznek elérhetők</p>
                        </li>
                    </ol>
                </div>
            </div>
            <div>
                <div className="py-4">
                    <h5 className="privacy-title">Fogalmak</h5>
                </div>
                <ul className='privacy-list'>
                    <li>
                        <span className="privacy-bold">Adatfeldolgozó:</span> természetes vagy jogi személy, közhatalmi szerv, ügynökség vagy bármely egyéb szerv, amely az adatkezelő nevében személyes adatokat kezel

                    </li>
                    <li>
                        <span className="privacy-bold">Adatkezelés:</span> a személyes adatokon vagy adatállományokon automatizált vagy nem automatizált módon végzett bármely művelet vagy műveletek összessége, például: gyűjtés, rögzítés, rendszerezés, tagolás, tárolás, átalakítás, megváltoztatás, lekérdezés, betekintés, felhasználás, közlés, továbbítás, terjesztés, egyéb módon történő hozzáférhetővé tétel, összehangolás, összekapcsolás, korlátozás, törlés, megsemmisítés
                    </li>
                    <li>
                        <span className="privacy-bold">Adattovábbítás:</span> az Adatkezelő által kezelt személyes adatok harmadik személyek számára történő hozzáférhetővé tétele

                    </li>
                    <li>
                        <span className="privacy-bold">Adatvédelmi incidens:</span> a biztonság sérülése, amely a továbbított, tárolt vagy más módon kezelt személyes adatok véletlen vagy jogellenes megsemmisítését, elvesztését, megváltoztatását, jogosulatlan közlését vagy az azokhoz való jogosulatlan hozzáférést eredményezi

                    </li>
                    <li>
                        <span className="privacy-bold">ÁSZF:</span> a weboldalon közzétett, az Adatkezelő termék- és szolgáltatásértékesítési szabályait rögzítő Általános Szerződési Feltételek
                    </li>
                    <li>
                        <span className="privacy-bold">Azonosítható természetes személy:</span> természetes személy, aki közvetlen vagy közvetett módon, valamely azonosító, például: név, azonosító szám, helymeghatározó adat, online azonosító, fizikai, fiziológiai, genetikai, szellemi, gazdasági, kulturális vagy szociális azonosságára vonatkozó egy vagy több tényező alapján azonosítható

                    </li>
                    <li>
                        <span className="privacy-bold">Címzett:</span>természetes vagy jogi személy, közhatalmi szerv, ügynökség vagy bármely egyéb szerv, amellyel a személyes adatot közlik, függetlenül attól, hogy harmadik fél-e
                    </li>
                    <li>
                        <span className="privacy-bold">Érintett hozzájárulása:</span>  az érintett akaratának önkéntes, konkrét, megfelelő tájékoztatáson alapuló, egyértelmű kinyilvánítása, amellyel az érintett nyilatkozat vagy a megerősítést félreérthetetlenül kifejező cselekedet útján jelzi, hogy beleegyezését adja az őt érintő személyes adatok kezeléséhez
                    </li>
                    <li>
                        <span className="privacy-bold">Érintett:</span> bármely információ alapján azonosított vagy azonosítható természetes személy
                    </li>
                    <li>
                        <span className="privacy-bold">Felhasználók:</span> az Adatkezelő által üzemeltetett, https://westendautorental.hu/ weboldalon és az ott meghatározott egyéb címeken elérhető weboldalak látogatói
                    </li>
                    <li>
                        <span className="privacy-bold">Harmadik fél:</span> természetes vagy jogi személy, közhatalmi szerv, ügynökség vagy bármely egyéb szerv, amely nem azonos az érintettel, az adatkezelővel, az adatfeldolgozóval vagy azokkal a személyekkel, akik az adatkezelő vagy adatfeldolgozó közvetlen irányítása alatt a személyes adatok kezelésére felhatalmazást kaptak
                    </li>
                    <li>
                        <span className="privacy-bold">Hatóság:</span>  Nemzeti Adatvédelmi és Információszabadság Hatóság (székhely: 1125 Budapest, Szilágyi Erzsébet fasor 22/c., telefon: 06-1 391-1400, fax: 06-1 391-1410, e-mail: ugyfelszolgalat@naih.hu, weboldal: naih.hu)
                    </li>
                    <li>
                        <span className="privacy-bold">Partner/Ügyfél:</span> természetes személy, aki az Adatkezelővel nem weboldalon köt szerződést termék értékesítésére vagy szolgáltatás nyújtására
                    </li>
                    <li>
                        <span className="privacy-bold">Regisztráció:</span> a weboldal felhasználó személyes adatainak rögzítése, mellyel felhasználói fiókot hoz létre
                    </li>
                    <li>
                        <span className="privacy-bold">Személyes adat:</span> : az érintettre vonatkozó bármely információ
                    </li>
                    <li>
                        <span className="privacy-bold">Szolgáltatás:</span> az Adatkezelő által Ügyfelei, Partnerei és Felhasználói részére a weboldalon vagy egyéb módon nyújtott, az ÁSZF-ben részletezett termékértékesítési és egyéb szolgáltatások

                    </li>
                    <li>
                        <span className="privacy-bold">Weboldal:</span> az Adatkezelő által üzemeltetett, HochTech Kft. és az ott meghatározott egyéb címeken elérhető weboldalai
                    </li>
                </ul>
            </div>
        </div>
    )
}