export const accordionData = [
  {
    title: 'Széles választék:',
    content: <strong>Széles válaszzték.</strong>,
    description:'Kínálatunkban megtalálhatók a legkülönbözőbb autótípusok, így mindenki megtalálja a számára legmegfelelőbb járművet.'
  },
  {
    title: 'Rugalmas feltételek:',
    content: <strong>Rugalmas feltételek:</strong>,
    description:'Az autókölcsönzés minden szempontjában rugalmasak vagyunk. Kézi vagy automata váltós autókat kínálunk, rövid- és hosszútávú bérlési lehetőségekkel.'
  },
  {
    title: 'Kiváló szolgáltatás:',
    content: <strong>Kiváló szolgáltatások:</strong>,
    description:'A kiváló ügyfélszolgáltatás a mi védjegyünk. Készen állunk arra, hogy segítsünk minden kérdésben vagy kérésben.'
  },
  {
    title: 'Megfizethető árak:',
    content: <strong>Megfizethető árak:</strong>,
    description:'Megfizethető árak: Folyamatosan versenyképes árakat kínálunk, hogy Önnek ne kelljen többet fizetnie egy minőségi bérlésért.'
  },
];
  