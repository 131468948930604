import React, { useState , useEffect, useContext } from 'react';
import DashNav from '../components/dashboard/DashNav';
import DashSlide from '../components/dashboard/DashSlide';
import { useNavigate } from "react-router-dom";
import DashHome from '../components/dashboard/DashHome';
import { AuthContext } from "../context/AuthContext";

export default function Dashboard(){
    const [selectedComponent, setSelectedComponent] = useState(<DashHome />);
    const [openMenu , setOpenMenu] = useState(false);
    const {dispatch} = useContext(AuthContext)
    const navigate = useNavigate();
    const handleOpenMenu = () => {
        setOpenMenu(!openMenu)
    }

    const handleLogout = () => {
        dispatch({ type: "LOGOUT" });
        navigate("/login");
    };

    const handleSidebarClick = (component) => {
        setSelectedComponent(component);
    }

    useEffect(() => {
        // Alapértelmezett komponens beállítása, ha még egyik menüpontra sem kattintottak
        if (!selectedComponent) {
            setSelectedComponent(<DashHome />);
        }
    }, [selectedComponent]);

    return(
        <div className='overflow-x-hidden'>   
            <DashNav handleLogout={handleLogout} handleOpenMenu={handleOpenMenu} />
            <div className="contaier-fluid">
                <div className="row">
                    <div>
                        <DashSlide openMenu={openMenu} handleOpenMenu={handleOpenMenu} handleSidebarClick={handleSidebarClick} />
                    </div>
                    <div className="col text-black dashboard-card mt-4">
                        {selectedComponent}
                    </div>
                </div>
            </div>
        </div>
    )
}