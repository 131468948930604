import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';
import { FreeMode, Navigation, Thumbs, Pagination } from 'swiper/modules';

export default function Sliders({ imageUrls }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [mainSwiper, setMainSwiper] = useState(null);

  const images = imageUrls || [];

  const handleThumbClick = (index) => {
    if (mainSwiper && mainSwiper.slideTo) {
      mainSwiper.slideTo(index);
    }
  };

  return (
<div className="mySwiper2">
      <Swiper
        onSwiper={setMainSwiper}  // Megváltoztatva
        style={{
          '--swiper-navigation-color': '#0d6efd',
          '--swiper-pagination-color': '#0d6efd',
        }}
        spaceBetween={10}
        navigation={true}
        pagination={{ clickable: true }}
        modules={[Navigation, Pagination]}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <img
              className="img-fluid py-1"
              id='sliders-img'
              src={image}
              alt={`Image ${index + 1}`}
              style={{ width: '100%'  }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}  // Megváltoztatva
        spaceBetween={5}
        slidesPerView={4}
        freeMode={true}
        watchslidesvisibility="true" 
        watchSlidesProgress={true}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index} onClick={() => handleThumbClick(index)}>
            <img
              className="img-fluid"
              id='sliders-img1'
              src={image}
              alt={`Thumbnail ${index + 1}`}
              style={{ width: '100%', }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}