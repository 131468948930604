import React from "react";
import {BiMenuAltRight} from 'react-icons/bi'
import Logo from '../../assets/logo/logo.png'

export default function DashNav({handleLogout, handleOpenMenu}) {

    return (
        <header className="navbar bg-dark flex-md-nowrap p-0 shadow" data-bs-theme="dark">
            <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6 text-white" href="#">
                <img 
                    src={Logo} 
                    alt="" 
                    className="navbar-logo-img"
                />
            </a>
            <div className="mx-3">
                <button className="btn btn-primary" onClick={handleLogout}>Kijelentkezés</button>
            </div>
            <ul className="navbar-nav flex-row d-md-none">
                <li className="nav-item text-nowrap">
                    <button 
                        className="nav-link px-3 text-white" 
                        type="button" 
                        data-bs-toggle="collapse" 
                        data-bs-target="#navbarSearch" 
                        aria-controls="navbarSearch" 
                        aria-expanded="false" aria-label="Toggle search"
                    >
                    </button>
                </li>
                <li className="nav-item text-nowrap">
                    <button 
                        className="nav-link px-3 text-white" 
                        type="button" 
                        data-bs-toggle="offcanvas" 
                        data-bs-target="#sidebarMenu" 
                        aria-controls="sidebarMenu" 
                        aria-expanded="false" 
                        aria-label="Toggle navigation"
                        onClick={handleOpenMenu}
                    ><BiMenuAltRight /></button>
                </li>
            </ul>
        </header>
    );
}

